<template>
    <svg class="stat-circle" width="170" viewBox="0 0 20 20">
      <defs>
        <linearGradient id="gradient" gradientTransform="rotate(90)">
          <stop offset="0%" stop-color="#33349a" />
          <stop offset="100%" stop-color="#037ee5" />
        </linearGradient>
      </defs>
      <circle class="bg" cx="10" cy="10" r="8" />
      <circle class="progress" cx="10" cy="10" r="8" :data-percentage="percentage" :style="circleStyle" />
      <text x="50%" y="34%" class="name">{{ name }}</text>
      <text x="50%" y="57%" class="percentage">{{ percentage }}%</text>
      <text x="50%" y="73%" class="people">{{ formattedPeople }}</text>
    </svg>
  </template>
  
  <script>
  export default {
    name: "StatCircle",
    props: {
      name: String,
      percentage: Number,
      people: Number,
    },
    computed: {
      circleStyle() {
        const offset = -51 - (51 / 100) * this.percentage;
        return {
          strokeDashoffset: offset,
          transition: "stroke-dashoffset 0.4s ease",
        };
      },
      formattedPeople() {
        return this.people.toLocaleString() + " คน";
      },
    },
  };
  </script>
  
  <style scoped>
  .stat-circle {
    circle.bg {
      fill: none;
      stroke: #f1f1f1;
      stroke-width: 1;
    }
    circle.progress {
      fill: none;
      stroke: #4284e5;
      stroke-width: 1;
      stroke-dasharray: 51 51;
      stroke-dashoffset: -51;
      stroke-linecap: round;
    }
    text {
      font-size: 3px;
      text-anchor: middle;
      fill: #555;
    }
    .name {
      font-size: 2px;
      text-anchor: middle;
      fill: #555;
    }
    .percentage {
      font-size: 4px;
      text-anchor: middle;
      font-weight: bold;
      line-height: 1em;
      fill: url(#gradient);
    }
    .people {
      font-size: 2px;
      text-anchor: middle;
      fill: #555;
    }
  }
  </style>
  