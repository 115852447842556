import { createStore } from "vuex";
import axios from "axios";

const store = createStore({
  state: {
    siteSettings: { terms: "" },
    siteLoaded: false,
    homeData: {},
    homeLoaded: false,
  },
  mutations: {
    setSiteSettings(state, settings) {
      state.siteSettings = settings;
      state.siteLoaded = true;
    },
    setHomeData(state, data) {
      state.homeData = data;
      state.homeLoaded = true;
    },
  },
  actions: {
    async fetchSiteSettings({ commit, state }) {
      if (!state.siteLoaded) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/site-settings`);
          commit("setSiteSettings", response.data);
        } catch (error) {
          console.error("Error fetching site settings:", error);
        }
      }
    },
    async fetchHomeData({ commit, state }) {
      if (!state.homeLoaded) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/home`);
          commit("setHomeData", response.data);
        } catch (error) {
          console.error("Error fetching home data:", error);
        }
      }
    },
  },
  getters: {
    siteSettings: (state) => state.siteSettings,
    siteLoaded: (state) => state.siteLoaded,
    homeData: (state) => state.homeData,
    homeLoaded: (state) => state.homeLoaded,
  },
});

export default store;
