import Swal from "sweetalert2";

export const showAlert = (options) => {
  const defaultOptions = {
    title: "แจ้งเตือน",
    text: "",
    confirmButtonText: "ตกลง",
  };

  const config = { ...defaultOptions, ...options };

  Swal.fire(config);
};

export function resizeImage(file, maxWidth, maxHeight, callback) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate aspect ratio
      const aspectRatio = width / height;
      const targetAspectRatio = maxWidth / maxHeight;

      let sx, sy, sWidth, sHeight;

      // Determine crop dimensions based on aspect ratio comparison
      if (aspectRatio > targetAspectRatio) {
        // Image is wider than target
        sWidth = height * targetAspectRatio;
        sHeight = height;
        sx = (width - sWidth) / 2;
        sy = 0;
      } else {
        // Image is taller than target
        sWidth = width;
        sHeight = width / targetAspectRatio;
        sx = 0;
        sy = (height - sHeight) / 2;
      }

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = maxWidth;
      canvas.height = maxHeight;

      ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, maxWidth, maxHeight);

      canvas.toBlob(
        (blob) => {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          const resizedImageBase64 = canvas.toDataURL(file.type, 1);
          callback(resizedFile, resizedImageBase64);
        },
        file.type,
        1
      );
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
}
