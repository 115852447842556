import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vSelect from "vue-select";
import SvgIcon from "vue3-icon";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import store from "./store/siteSettings";
import "@vuepic/vue-datepicker/dist/main.css";
import "./assets/css/main.css";
import "./assets/css/fonts.css";
import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

const app = createApp(App);
app.component("VueDatePicker", VueDatePicker);
app.component("svg-icon", SvgIcon);
app.component("v-select", vSelect);
app.use(VueSweetalert2);
app.use(store);
app.use(Viewer);
app.use(router).mount("#app");
