import jwt_decode from "jwt-decode";

export function isLoggedIn() {
  const token = localStorage.getItem("authTokenWeb");
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwt_decode(token);
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > decodedToken.exp) {
      localStorage.removeItem("authTokenWeb");
      localStorage.removeItem("userInfoWeb");
      localStorage.removeItem("needsSurvey");
      return false;
    }

    return true;
  } catch (error) {
    localStorage.removeItem("authTokenWeb");
    localStorage.removeItem("userInfoWeb");
    localStorage.removeItem("needsSurvey");
    return false;
  }
}

export function isLoggedInAdmin() {
  const token = localStorage.getItem("authTokenAdmin");
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwt_decode(token);
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > decodedToken.exp) {
      localStorage.removeItem("authTokenAdmin");
      localStorage.removeItem("userInfo");
      return false;
    }

    return true;
  } catch (error) {
    localStorage.removeItem("authTokenAdmin");
    localStorage.removeItem("userInfo");
    return false;
  }
}
