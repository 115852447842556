<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container my-5">
    <h1 class="text-primary text-header text-start">ยืนยันตัวตนสิทธิ</h1>
    <div class="row d-flex justify-content-center align-items-center mt-5">
      <div @click="openVolunteerPopup" class="col-12 col-md-4 circle-item text-center my-2">
        <div class="circle-image-container">
          <img :src="require(`../assets/images/img-general.jpg`)" class="circle-image" />
        </div>
        <p class="button button-secondary mt-3">อาสาสมัคร</p>
      </div>
      <div @click="openStaffPopup" class="col-12 col-md-4 circle-item text-center my-2">
        <div class="circle-image-container">
          <img :src="require(`../assets/images/img-officer.jpg`)" class="circle-image" />
        </div>
        <p class="button button-secondary mt-3">เจ้าหน้าที่</p>
      </div>
    </div>

    <!-- Volunteer Password Modal -->
    <div ref="volunteerModalRef" class="modal fade" id="volunteerModal" tabindex="-1" aria-labelledby="volunteerModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="volunteerModalLabel">กรุณายืนยันตัวตนอาสาสมัคร</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-1">
              <label for="birthdate" class="form-label">วันเดือนปีเกิด</label>
              <input
                type="text"
                class="form-control"
                id="birthdate"
                v-model="birthdate"
                placeholder="วันเดือนปีเกิด"
                maxlength="8"
                @input="validateNumberInput"
                title="กรุณากรอกวันเดือนปีเกิด 8 หลัก"
              />
              <div class="form-text">วัน-เดือน-ปี ตัวอย่างเช่น : 31052550</div>
              <div v-if="birthdateError" class="text-danger">{{ birthdateError }}</div>
            </div>
            <div v-if="loginMessage" class="text-danger">{{ loginMessage }}</div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-primary w-50" @click="login">ยืนยัน</button>
            <button type="button" class="btn btn-secondary w-50" data-bs-dismiss="modal">ย้อนกลับ</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Staff Username and Password Modal -->
    <div ref="staffModalRef" class="modal fade" id="staffModal" tabindex="-1" aria-labelledby="staffModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staffModalLabel">กรุณายืนยันตัวตนเจ้าหน้าที่</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="staffUsername" class="form-label">ชื่อผู้ใช้</label>
              <input type="text" class="form-control" id="staffUsername" v-model="staffUsername" placeholder="กรอกชื่อผู้ใช้" />
            </div>
            <div class="mb-3">
              <label for="staffPassword" class="form-label">รหัสผู้ใช้งาน</label>
              <input type="password" class="form-control" id="staffPassword" v-model="staffPassword" placeholder="กรอกรหัสผู้ใช้งาน" />
            </div>
            <div v-if="staffError" class="text-danger">{{ staffError }}</div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-primary w-50" @click="submitStaffCredentials">ยืนยัน</button>
            <button type="button" class="btn btn-secondary w-50" data-bs-dismiss="modal">ย้อนกลับ</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Pop-up Modal for Messages -->
    <div ref="messageModalRef" class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="messageModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="messageModalLabel">แจ้งเตือน</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ popupMessage }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">ปิด</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, nextTick } from "vue";

export default {
  name: "PermissionCheckPage",
  setup() {
    const idCard = ref("");
    const birthdate = ref("");
    const staffUsername = ref("");
    const staffPassword = ref("");
    const birthdateError = ref("");
    const staffError = ref("");
    const loginMessage = ref("");
    const loading = ref(false);
    const popupMessage = ref("");

    const volunteerModalRef = ref(null);
    const staffModalRef = ref(null);
    const messageModalRef = ref(null);

    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      if (route.query.citizenId) {
        idCard.value = route.query.citizenId;
      }
    });

    const openVolunteerPopup = async () => {
      await nextTick();
      const modal = new Modal(volunteerModalRef.value);
      modal.show();
    };

    const openStaffPopup = async () => {
      await nextTick();
      const modal = new Modal(staffModalRef.value);
      modal.show();
    };

    const login = () => {
      let valid = true;
      const citizenId = route.query.citizenId;

      if (idCard.value.length !== 13) {
        birthdateError.value = "กรุณากรอกหมายเลขบัตรประชาชนต้องมี 13 หลัก";
        valid = false;
      } else {
        birthdateError.value = "";
      }

      if (birthdate.value.length !== 8) {
        birthdateError.value = "กรุณากรอกวันเดือนปีเกิดต้องมี 8 หลัก";
        valid = false;
      } else {
        birthdateError.value = "";
      }

      if (!valid) {
        return;
      }

      loading.value = true;
      loginMessage.value = "";

      axios
        .post(`${process.env.VUE_APP_API_URL}/login-web`, { username: idCard.value, password: birthdate.value })
        .then((response) => {
          loading.value = false;
          if (response.status == 200) {
            localStorage.setItem("authTokenWeb", response.data.token);
            localStorage.setItem("userInfoWeb", JSON.stringify(response.data.data));

            localStorage.removeItem("authTokenAdmin");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("menu");

            const volunteerModal = Modal.getInstance(volunteerModalRef.value);
            volunteerModal.hide();
            router.push(`/profile?citizenId=${citizenId}`);
          } else {
            loginMessage.value = response.data.message;
          }
        })
        .catch((error) => {
          loading.value = false;
          if (error.response) {
            loginMessage.value = error.response.data.message;
          } else {
            loginMessage.value = error.message;
          }
        });
    };

    const validateNumberInput = (event) => {
      // กำจัดข้อความที่ไม่ใช่ตัวเลขและจำกัดความยาว
      let value = event.target.value.replace(/\D/g, "");
      if (value.length > 8) {
        value = value.slice(0, 8);
      }
      event.target.value = value;
    };

    const submitStaffCredentials = () => {
      if (!staffUsername.value || !staffPassword.value) {
        staffError.value = "กรุณากรอกชื่อผู้ใช้และรหัสผ่านให้ครบถ้วน";
        return;
      }

      staffError.value = "";
      loading.value = true;
      const citizenId = route.query.citizenId;
      axios
        .post(`${process.env.VUE_APP_API_URL}/login-admin`, { username: staffUsername.value, password: staffPassword.value })
        .then((response) => {
          loading.value = false;
          if (response.status == 200) {
            const userInfo = response.data.data;
            const menu = response.data.menu;
            localStorage.setItem("authTokenAdmin", response.data.token);
            localStorage.setItem("menu", JSON.stringify(menu));
            localStorage.setItem("userInfo", JSON.stringify(userInfo));

            localStorage.removeItem("authTokenWeb");
            localStorage.removeItem("userInfoWeb");

            const staffModal = Modal.getInstance(staffModalRef.value);
            staffModal.hide();
            router.push(`/profile?citizenId=${citizenId}`);
          } else {
            staffError.value = response.data.message;
          }
        })
        .catch((error) => {
          loading.value = false;
          if (error.response) {
            staffError.value = error.response.data.message;
          } else {
            staffError.value = error.message;
          }
        });
    };

    return {
      idCard,
      birthdate,
      staffUsername,
      staffPassword,
      birthdateError,
      staffError,
      loginMessage,
      loading,
      popupMessage,
      volunteerModalRef,
      staffModalRef,
      messageModalRef,
      openVolunteerPopup,
      openStaffPopup,
      login,
      validateNumberInput,
      submitStaffCredentials,
    };
  },
};
</script>

<style scoped>
.circle-item {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.circle-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.circle-item:hover .circle-image-container {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .circle-image-container {
    width: 250px;
    height: 250px;
  }
}
</style>
