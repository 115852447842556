<template>
  <div>
    <header :class="headerClass" :style="{ height: headerHeight }">
      <figure :style="figureStyle"></figure>
      <div class="site-header default">
        <div :class="containerClass">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="brand">
                <router-link to="/" class="link">
                  <div class="thumb d-flex align-items-center my-4 animate__animated animate__fadeInDown animate__slow">
                    <img class="l1" src="@/assets/images/brand-mnre.png" alt="Brand MNRE" />
                    <img class="l2" src="@/assets/images/logo.png" alt="Logo" />
                    <img class="l3" src="@/assets/images/brand.png" alt="Brand" />
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-md-6 d-flex flex-column align-items-md-end" v-if="showHomePage">
              <div :class="menuContainerClass">
                <h2 class="menu-title animate__animated animate__fadeInDown animate__slow">{{ currentMenu }}</h2>
                <nav class="breadcrumb-nav animate__animated animate__fadeInUp animate__slow">
                  <ul class="breadcrumb mb-0">
                    <li class="breadcrumb-item" v-for="(item, index) in breadcrumb" :key="index">
                      <router-link :to="item.path"><i :class="item.icon"></i> {{ item.text }}</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="row text-start header-text" v-if="!showHomePage">
            <p class="col-12 header-title">MARINE RANGERS</p>
            <p class="col-12 header-subtitle">
              <span>อาสาสมัครพิทักษ์ทะเล</span>
              <br />
              <span><strong>(อสทล.)</strong></span>
            </p>
            <div class="col-12 my-1">
              <router-link to="/terms" class="btn btn-lg custom-btn">สมัครสมาชิกอาสาสมัครพิทักษ์ทะเล</router-link>
            </div>
            <div class="col-12 my-2">
              <a href="#login" class="btn btn-lg custom-btn">ตรวจสอบสถานภาพ</a>
            </div>
            <div class="col-12 my-4">
              <div class="member-count">
                <span>จำนวน อสทล.</span>
                <div class="member-number">
                  <span>{{ formatNumber(homeData?.volunteer_total) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div :class="containerClass" class="mt-2">
      <div class="row text-center header-text-m" v-if="!showHomePage">
        <div class="col-12 header-title">MARINE RANGERS</div>
        <div class="col-12 header-subtitle mt-2 mb-3">
          <span>อาสาสมัครพิทักษ์ทะเล (อสทล.)</span>
        </div>
        <div class="col-12 my-1">
          <router-link to="/terms" class="btn btn-lg custom-btn">สมัครสมาชิกอาสาสมัครพิทักษ์ทะเล</router-link>
        </div>
        <div class="col-12 my-1">
          <a href="#login" class="btn btn-lg custom-btn">ตรวจสอบสถานภาพ</a>
        </div>
        <div class="col-12 my-4">
          <div class="member-count text-start">
            <span>จำนวน อสทล.</span>
            <div class="member-number">
              <span>{{ formatNumber(homeData?.volunteer_total) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
import { menuDisplay } from "../data/menuDisplay";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      currentMenu: "",
      breadcrumb: [],
      containerClass: window.innerWidth <= 992 ? "container-fluid" : "container",
      menuContainerClass: window.innerWidth >= 768 ? "text-md-end" : "text-start",
      headerHeight: "auto",
      headerBackground: "",
      homeData: null,
    };
  },
  computed: {
    showHomePage() {
      return this.$route.path !== "/";
    },
    figureStyle() {
      const imgSrc = this.$route.path === "/" && this.homeData?.topgraphic ? this.homeData.topgraphic : require("@/assets/images/default-header.jpg");

      return {
        backgroundImage: `url(${imgSrc})`,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        backgroundPosition: "center",
        backgroundSize: "cover",
      };
    },
    headerClass() {
      return {
        "app-header": true,
        "mb-0": this.$route.path === "/",
        "mb-n4": this.$route.path !== "/",
      };
    },
  },
  watch: {
    $route(to) {
      this.updateCurrentMenu(to);
      this.setBackground();
    },
    homeData: {
      handler() {
        this.setBackground();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.updateCurrentMenu(this.$route);
    this.homeData = this.getHomeData();
    window.addEventListener("resize", this.setBackground);
    window.addEventListener("resize", this.updateClasses);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setBackground);
    window.removeEventListener("resize", this.updateClasses);
  },
  methods: {
    updateCurrentMenu(route) {
      const path = route.path;
      let menuData = menuDisplay[path];
      if (!menuData) {
        const dynamicPath = Object.keys(menuDisplay).find(key => {
          const regex = new RegExp(`^${key.replace(/:[^\s/]+/g, '([^/]+)')}$`);
          return regex.test(path);
        });
        menuData = menuDisplay[dynamicPath];
      }

      this.currentMenu = menuData ? menuData.title : "";
      this.breadcrumb = menuData ? menuData.breadcrumb : [];
    },
    updateClasses() {
      this.containerClass = window.innerWidth <= 992 ? "container-fluid" : "container";
      this.menuContainerClass = window.innerWidth >= 768 ? "text-md-end" : "text-start";
    },
    setBackground() {
      const imgSrc = this.$route.path === "/" && this.homeData?.topgraphic ? this.homeData.topgraphic : require("@/assets/images/default-header.jpg");

      this.loadImage(imgSrc, (width, height) => {
        const aspectRatio = height / width;
        const headerWidth = this.$el.clientWidth;
        const headerHeight = headerWidth * aspectRatio;
        if (headerHeight > 330) {
          this.headerHeight = `${headerHeight}px`;
        } else {
          this.headerHeight = `330px`;
        }
        this.headerBackground = imgSrc;
      });
    },
    loadImage(src, callback) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        callback(img.width, img.height);
      };
    },
    getHomeData() {
      const store = useStore();
      return computed(() => store.getters.homeData);
    },
    formatNumber(value) {
      if (!value) return "0";
      return new Intl.NumberFormat().format(value);
    },
  },
};
</script>

<style scoped>
.app-header {
  position: relative;
  color: #fff;
  overflow: hidden;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-n4 {
  margin-bottom: -40px;
}

figure {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.site-header {
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 120px;
  background: url("@/assets/images/site-header.png") left top no-repeat;
  background-size: contain;
}

.brand .thumb img {
  margin-right: 10px;
}

.menu-title {
  margin: 0;
  z-index: 101;
  position: relative;
  text-transform: uppercase;
}

.breadcrumb-nav {
  margin-top: 10px;
}

.breadcrumb {
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.breadcrumb-item {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url("@/assets/images/bc-arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: 6px;
  margin-left: 0px;
  margin-right: 8px;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
nav ul li {
  display: inline;
}
nav ul li a {
  color: #fff;
  text-decoration: none;
}
nav ul li a:hover {
  text-decoration: underline;
}

.breadcrumb-nav {
  font-size: 0.875rem;
}

.brand .thumb {
  width: 100%;
  display: flex;
  justify-content: center;
}

.brand .thumb img.l1,
.brand .thumb img.l2 {
  width: 70px;
}
.brand .thumb img.l3 {
  width: 90px;
}
.menu-title {
  font-size: 1.8rem;
}

.header-text {
  display: none;
}
.header-text-m {
  display: block;
}

.header-title {
  color: #fff;
  text-shadow: -1px 0 #037ee5, 0 1px #037ee5, 1px 0 #037ee5, 0 -1px #037ee5;
  font-size: 34px;
}

.header-subtitle {
  color: #037ee5;
  border: #fff;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  line-height: 1.5em;
}

.header-subtitle > span {
  font-size: 35px;
  font-weight: 500;
}

.header-subtitle > span > strong {
  font-size: 65px;
}

.custom-btn {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(to bottom, #037de4 0%, #037de4 50%, #33349a 100%);
  font-size: 18px;
  background-size: auto 200%;
  border-radius: 40px;
  width: 100%;
  transition: background-position 0.4s;
  background-position: center bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-btn.btn-lg {
  height: 65px;
}

.custom-btn:hover {
  background: #037de4;
}

.member-count {
  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  border-radius: 5px;
  height: 86px;
  line-height: 64px;
  padding: 12px 16px;
}
.member-count > span {
  font-weight: bold;
  font-size: 20px;
  background: linear-gradient(to top, #33349a 0%, #037ee5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.member-number {
  float: right;
  position: relative;
  line-height: 65px;
  font-weight: bold;
  font-size: 26px;
  padding: 0 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: -moz-linear-gradient(top, #e5e5e5 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #e5e5e5 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #e5e5e5 0%, #ffffff 100%);
}

.member-number > span {
  background: linear-gradient(to top, #33349a 0%, #037ee5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Media Queries for responsive sizes */
@media (max-width: 576px) {
  .site-header {
    background-size: 100px 100px;
  }
  .brand .thumb img {
    width: 60px;
  }
  .menu-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .site-header {
    background-size: 150px 150px;
  }
  .brand .thumb {
    justify-content: center;
  }
  .brand .thumb img {
    width: 80px;
  }
  .menu-title {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .app-header {
    height: auto;
  }
  .site-header {
    background-size: 540px;
  }
  .brand .thumb {
    justify-content: flex-end;
  }
  .brand .thumb img {
    width: 100px;
  }
  .menu-title {
    font-size: 3rem;
  }
  .breadcrumb-nav {
    font-size: 1rem;
  }
  .brand .thumb img.l1,
  .brand .thumb img.l2 {
    width: 100px;
  }
  .brand .thumb img.l3 {
    width: 120px;
  }

  .header-subtitle {
    line-height: 1em;
  }

  .header-subtitle > span {
    font-size: 45px;
    font-weight: 500;
  }

  .header-subtitle > span > strong {
    font-size: 65px;
  }

  .custom-btn {
    font-size: 20px;
    background-size: auto 200%;
    border-radius: 40px;
    width: 100%;
  }

  .custom-btn.btn-lg {
    height: 65px;
  }

  .member-count {
    height: 86px;
    line-height: 64px;
    padding: 12px 16px;
  }
  .member-count > span {
    margin-left: 22px;
    font-size: 26px;
  }

  .member-number {
    line-height: 65px;
    font-size: 42px;
    padding: 0 30px;
  }
}

@media (min-width: 1198px) {
  .brand .thumb img.l1,
  .brand .thumb img.l2 {
    width: 130px;
  }
  .brand .thumb img.l3 {
    width: 150px;
  }

  .header-text {
    display: block;
    margin-top: 30px;
  }

  .header-text-m {
    display: none;
  }

  .header-title {
    font-size: 24px;
    line-height: 0.6em;
  }

  .header-subtitle {
    line-height: 3em;
  }

  .custom-btn {
    font-size: 20px;
    background-size: auto 200%;
    border-radius: 40px;
    width: 100%;
    max-width: 500px;
  }

  .custom-btn.btn-lg {
    height: 65px;
  }

  .member-count {
    width: 500px;
  }
}

@media (min-width: 1400px) {
  .header-text {
    display: block;
    margin-top: 100px;
  }

  .header-subtitle > span {
    font-size: 65px;
    font-weight: 500;
  }

  .header-subtitle > span > strong {
    font-size: 85px;
  }

  .header-title {
    font-size: 30px;
    line-height: 0.6em;
  }

  .custom-btn {
    font-size: 24px;
    background-size: auto 200%;
    border-radius: 40px;
    width: 100%;
    max-width: 574px;
  }

  .custom-btn.btn-lg {
    height: 75px;
  }

  .member-count {
    width: 572px;
  }
}
</style>
