export const months = [
  { id: 1, name: "มกราคม", shortName: "ม.ค." },
  { id: 2, name: "กุมภาพันธ์", shortName: "ก.พ." },
  { id: 3, name: "มีนาคม", shortName: "มี.ค." },
  { id: 4, name: "เมษายน", shortName: "เม.ย." },
  { id: 5, name: "พฤษภาคม", shortName: "พ.ค." },
  { id: 6, name: "มิถุนายน", shortName: "มิ.ย." },
  { id: 7, name: "กรกฎาคม", shortName: "ก.ค." },
  { id: 8, name: "สิงหาคม", shortName: "ส.ค." },
  { id: 9, name: "กันยายน", shortName: "ก.ย." },
  { id: 10, name: "ตุลาคม", shortName: "ต.ค." },
  { id: 11, name: "พฤศจิกายน", shortName: "พ.ย." },
  { id: 12, name: "ธันวาคม", shortName: "ธ.ค." },
];
