<template>
  <div class="timeline">
    <div v-if="items && items.length > 0">
      <div class="timeline-header" @click="toggleAccordion">
        <div class="timeline-item d-flex justify-content-between">
          <div :class="['timeline-icon', items[0].completed ? 'completed' : '']">
            <i class="fa" :class="[items[0].completed ? 'fa-check' : 'fa-circle']"></i>
          </div>
          <div class="timeline-content text-start">
            <p class="mb-0 text-title" :class="{ 'text-muted': !items[0].completed }">
              <strong>{{ items[0].label }}</strong>
            </p>
            <p class="mb-0" :class="{ 'text-success': items[0].completed, 'text-muted': !items[0].completed }">วันที่: {{ items[0].date }}</p>
            <p class="mb-0 text-muted" v-html="getDescription(items[0])"></p>
          </div>
          <div v-if="items[0].button" class="ms-auto button-container">
            <button @click="items[0].button.action" class="btn btn-lg custom-btn btn-primary">{{ items[0].button.text }}</button>
          </div>
        </div>
      </div>
      <transition name="accordion">
        <div v-show="isExpanded">
          <div class="timeline-item d-flex justify-content-between" v-for="(status, index) in items.slice(1)" :key="index">
            <div :class="['timeline-icon', status.completed ? 'completed' : '']">
              <i class="fa" :class="[status.completed ? 'fa-check' : 'fa-circle']"></i>
            </div>
            <div class="timeline-content text-start">
              <p class="mb-0 text-title" :class="{ 'text-muted': !status.completed }">
                <strong>{{ status.label }}</strong>
              </p>
              <p class="mb-0" :class="{ 'text-success': status.completed, 'text-muted': !status.completed }">วันที่: {{ status.date }}</p>
              <p v-if="status.completed == 0" class="mb-0 text-muted">{{ status.description }}</p>
            </div>
            <div v-if="status.button" class="ms-auto button-container">
              <button @click="status.button.action" class="btn btn-lg custom-btn btn-primary">{{ status.button.text }}</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else>
      <p>No items to display</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineComponent",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isExpanded = !this.isExpanded;
    },
    getDescription(item) {
      let description = item.description;
      if (item.code === "009" && description) {
        description += ' << <a href="https://track.thailandpost.com" target="_blank">ตรวจสอบสถานะจัดส่ง</a>';
      }
      return description;
    },
  },
};
</script>

<style scoped>
.timeline {
  position: relative;
  margin-top: 2rem;
  padding-left: 4rem;
}

.timeline-header {
  cursor: pointer;
}

.timeline-header:hover {
  color: #206fbf;
}

.timeline-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 44px;
  bottom: 0;
  left: -1.5rem;
  width: 2px;
  height: 100%;
  background: #e0e0e0;
}

.timeline-item {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 2rem;
}

.timeline-item:last-child::before {
  height: 44px;
}

.timeline-icon {
  position: absolute;
  left: -3rem;
  top: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-icon.completed {
  border: 4px solid #e0e0e0;
  background: #58bd44;
}

.timeline-icon i {
  color: white;
}

.text-muted {
  font-size: 14px;
}

.text-title strong {
  font-size: 16px;
}

.custom-btn {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(to bottom, #037de4 0%, #037de4 50%, #33349a 100%);
  font-size: 18px;
  background-size: auto 200%;
  border-radius: 40px;
  width: 100%;
  transition: background-position 0.4s;
  background-position: center bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-btn.btn-lg {
  height: 55px;
}

.custom-btn:hover {
  background: #037de4;
}

.accordion-enter,
.accordion-leave-to {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

@media (max-width: 576px) {
  .information {
    font-size: 1rem;
    padding-left: 40px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .timeline-item {
    flex-direction: column;
  }

  .timeline-content {
    text-align: center;
    height: 70px;
  }
}
</style>
