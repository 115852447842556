<template>
  <div class="container my-5">
    <h2 class="text-primary mb-4">แบบสอบถามความพึงพอใจของอาสาสมัครพิทักษ์ทะเล</h2>

    <!-- Loading overlay -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>

    <div v-for="section in surveySections" :key="section.id" class="mb-5">
      <h3 class="mb-4 text-left">{{ section.header1 }}</h3>
      <div v-if="section.subsections && section.subsections.length > 0">
        <div v-for="subsection in section.subsections" :key="subsection.id" class="mb-4">
          <h5 v-if="subsection.header2" class="mb-3 text-left">{{ subsection.header2 }}</h5>
          <div v-for="question in subsection.questions" :key="question.id" class="mb-3">
            <component :is="subsection.header2 ? 'h6' : 'h5'" class="text-left">
              {{ question.question_text }}
            </component>
            <div class="row align-items-center">
              <div v-for="option in question.options" :key="option.id" :class="question.columns" class="mb-2 d-flex align-items-center">
                <div class="form-check d-flex align-items-center w-100">
                  <input
                    ref="inputs"
                    class="form-check-input me-2"
                    :type="getInputType(question.question_type)"
                    :name="'question-' + question.id"
                    :id="'question-' + question.id + '-option-' + option.id"
                    :value="option.id"
                    v-model="selectedOptions[question.id]"
                    @change="handleOtherOptionChange(question.id, option)"
                  />
                  <label class="form-check-label" :for="'question-' + question.id + '-option-' + option.id">
                    {{ option.option_text }}
                  </label>
                  <input
                    v-if="showOtherInput[question.id] && option.is_other"
                    ref="inputs"
                    type="text"
                    class="form-control ms-2"
                    v-model="otherText[question.id]"
                    placeholder="โปรดระบุ"
                    style="width: auto"
                  />
                </div>
              </div>
              <div v-if="validationErrors[question.id]" class="text-danger mt-2 text-left">
                {{ validationErrors[question.id] }}
              </div>
            </div>
            <div v-if="question.question_type === 4" class="mt-3">
              <textarea ref="inputs" class="form-control" v-model="selectedOptions[question.id]" placeholder="กรอกคำตอบของคุณที่นี่..." rows="4" style="width: 100%"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="saveSurveyAnswers" class="btn btn-primary me-2">บันทึกคำตอบ</button>
    <button @click="skipSurvey" class="btn btn-secondary">ตอบคำถามภายหลัง</button>
  </div>
</template>

<script>
import axios from "axios";
import { showAlert } from "@/utils/utils";

export default {
  name: "SurveyPage",
  data() {
    return {
      surveySections: [],
      selectedOptions: {},
      showOtherInput: {},
      otherText: {},
      validationErrors: {},
      loading: false,
    };
  },
  created() {
    this.loadSurveyQuestions();
  },
  mounted() {
    this.focusFirstInvalidInput();
  },
  methods: {
    focusFirstInvalidInput() {
      this.$nextTick(() => {
        const inputs = this.$refs.inputs;
        if (inputs && inputs.length > 0) {
          const invalidInput = inputs.find((input) => {
            const questionId = input.name?.split("-")[1];
            return this.validationErrors[questionId];
          });
          if (invalidInput) {
            invalidInput.focus();
          }
        }
      });
    },
    async saveSurveyAnswers() {
      if (this.validateInputs()) {
        this.loading = true;
        const answers = Object.keys(this.selectedOptions)
          .map((questionId) => {
            const question = this.surveySections
              .flatMap((section) => section.subsections)
              .flatMap((subsection) => subsection.questions)
              .find((q) => q.id == questionId);

            const optionList = question.question_type === 4 ? null : Array.isArray(this.selectedOptions[questionId]) ? this.selectedOptions[questionId].join(",") : this.selectedOptions[questionId];

            const answerText = question.question_type === 4 ? this.selectedOptions[questionId] : this.showOtherInput[questionId] ? this.otherText[questionId] : null;

            return {
              question_id: questionId,
              option_list: optionList || null,
              answer_text: answerText || null,
            };
          })
          .filter((answer) => answer !== null);

        if (answers.length > 0) {
          const token = localStorage.getItem("authTokenWeb");
          try {
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/survey-answers`, { answers }, { headers: { Authorization: `Bearer ${token}` } });
            this.loading = false;
            if (response.status === 200) {
              showAlert({ title: "ขอบคุณสำหรับข้อมูล\nความพึงพอใจ", icon: "success" });
              localStorage.removeItem("needsSurvey");
              this.$router.push("/profile");
            } else {
              alert("เกิดข้อผิดพลาดในการบันทึกคำตอบ");
            }
          } catch (error) {
            console.error(error);
            this.loading = false;
            alert("เกิดข้อผิดพลาดในการบันทึกคำตอบ");
          }
        } else {
          this.loading = false;
          alert("ไม่มีคำตอบที่จะบันทึก");
        }
      }
    },
    skipSurvey() {
      this.setItemWithExpiration("skipSurvey", "true", 7 * 24 * 60 * 60 * 1000);
      this.$router.push("/profile");
    },
    setItemWithExpiration(key, value, ttl) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    validateInputs() {
      this.validationErrors = {};
      let isValid = true;

      this.surveySections.forEach((section) => {
        section.subsections.forEach((subsection) => {
          subsection.questions.forEach((question) => {
            if (question.question_type === 4) return;

            if (!this.selectedOptions[question.id] || (Array.isArray(this.selectedOptions[question.id]) && this.selectedOptions[question.id].length === 0)) {
              this.validationErrors[question.id] = "กรุณาตอบคำถามนี้";
              isValid = false;
            }

            if (this.showOtherInput[question.id] && (!this.otherText[question.id] || this.otherText[question.id].trim() === "")) {
              this.validationErrors[question.id] = "กรุณาระบุข้อมูลในช่องอื่นๆ";
              isValid = false;
            }
          });
        });
      });

      if (!isValid) {
        this.focusFirstInvalidInput();
      }

      return isValid;
    },
    async loadSurveyQuestions() {
      this.loading = true;
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/survey-questions`)
          .then((response) => {
            if (response.status == 200) {
              let questions = response.data;
              this.surveySections = questions.surveySections;

              this.surveySections.forEach((section) => {
                section.subsections.forEach((subsection) => {
                  subsection.questions.forEach((question) => {
                    if (question.question_type === 2) {
                      this.selectedOptions[question.id] = [];
                    } else if (question.question_type === 1) {
                      this.selectedOptions[question.id] = null;
                    }
                    this.showOtherInput[question.id] = false;
                  });
                });
              });

              this.loadSurveyAnswers();
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async loadSurveyAnswers() {
      const token = localStorage.getItem("authTokenWeb");
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/survey-answers`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status == 200) {
              let answers = response.data;
              answers.answers.forEach((answer) => {
                if (answer.selected_option_id !== undefined) {
                  this.selectedOptions[answer.question_id] = answer.selected_option_id;
                } else if (answer.selected_option_ids !== undefined) {
                  this.selectedOptions[answer.question_id] = answer.selected_option_ids;
                } else if (answer.answer_text !== undefined) {
                  this.selectedOptions[answer.question_id] = answer.answer_text;
                }
                if (answer.other_text) {
                  this.showOtherInput[answer.question_id] = true;
                  this.otherText[answer.question_id] = answer.other_text;
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    getInputType(questionType) {
      switch (questionType) {
        case 1:
          return "radio";
        case 2:
          return "checkbox";
        default:
          return "text";
      }
    },
    handleOtherOptionChange(questionId, option) {
      const question = this.surveySections
        .flatMap((section) => section.subsections)
        .flatMap((subsection) => subsection.questions)
        .find((q) => q.id === questionId);

      if (question.question_type === 2) {
        if (option.is_other) {
          this.showOtherInput[question.id] = this.selectedOptions[question.id].includes(option.id);
        }

        const isAnyOtherSelected = question.options.filter((opt) => opt.is_other).some((opt) => this.selectedOptions[question.id].includes(opt.id));

        this.showOtherInput[question.id] = isAnyOtherSelected;
      } else if (question.question_type === 1) {
        if (option.is_other) {
          this.showOtherInput[question.id] = this.selectedOptions[question.id] === option.id;
        } else {
          this.showOtherInput[question.id] = false;
          this.otherText[questionId] = "";
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}
.form-check-input {
  margin-right: 10px;
}
.form-check-label {
  display: block;
  text-align: left;
}
.text-left {
  text-align: left;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-5 {
  margin-bottom: 3rem;
}
.form-check {
  display: flex;
  align-items: center;
}
.form-control {
  flex: 1;
  margin-left: 0;
  width: auto;
  max-width: 100%;
}
.text-danger {
  color: #dc3545;
  text-align: left;
}
</style>
