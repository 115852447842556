<template>
  <div>
    <!-- Existing Footer Content -->
    <footer class="footer bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-8 d-flex align-items-center flex-column flex-md-row text-center text-md-start animate__animated animate__fadeInLeft animate__slow">
            <img src="@/assets/images/logo.png" alt="Department Logo" class="footer-logo mr-md-3 mb-2 mb-md-0" />
            <div class="footer-text-container">
              <h5 class="text-foot text-second mb-0">
                กรมทรัพยากรทางทะเลและชายฝั่ง
                <br class="d-block d-md-none" />
                กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม
              </h5>
              <span class="text-muted">Department of Marine and Coastal Resources</span>
            </div>
          </div>
          <div class="col-md-4 animate__animated animate__fadeInRight animate__slow">
            <ul class="social-icons list-unstyled d-flex justify-content-center justify-content-md-end mb-0 flex-wrap">
              <li v-for="(link, name) in homeData.social" :key="name" class="social-icon-item">
                <a :href="link" class="btn btn-link p-0" target="_blank">
                  <img :src="getIcon(name)" :alt="name" class="social-icon" />
                </a>
              </li>
              <li class="social-icon-item">
                <a href="/admin" class="btn btn-link p-0">
                  <img src="@/assets/images/dolphin.png" alt="Dolphin" class="social-icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="policy-container mt-3 py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <ul class="policy-list list-inline animate__animated animate__fadeInUp animate__slow">
                <li class="list-inline-item"><a href="#" class="policy-link" @click.prevent="showModal('92')">นโยบายการรักษาความมั่นคงปลอดภัยเว็บไซต์</a></li>
                <li class="list-inline-item"><a href="#" class="policy-link" @click.prevent="showModal('93')">นโยบายเว็บไซต์</a></li>
                <li class="list-inline-item"><a href="#" class="policy-link" @click.prevent="showModal('94')">นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)</a></li>
                <li class="list-inline-item"><a href="#" class="policy-link" @click.prevent="showModal('2022373')">นโยบายคุกกี้</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Ontop Button -->
    <button v-if="showOntopButton" class="ontop-button" @click="scrollToTop">
      <svg-icon :fa-icon="faArrowUp" :size="16" class="icon-announcement"></svg-icon>
    </button>

    <!-- Modals -->
    <div v-for="(modalId, index) in modals" :key="index" :id="modalId" class="modal fade" tabindex="-1" aria-labelledby="modal-label" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="app-footer modal-body">
            <div v-if="modalContents[modalId]">
              <div class="text-center">
                <h5 class="modal-title" v-html="modalContents[modalId].list.subject"></h5>
                <p v-html="modalContents[modalId].list.title"></p>
              </div>
              <hr />
              <div v-html="modalContents[modalId].list.html"></div>
              <div class="mt-4" v-if="modalContents[modalId].file && modalContents[modalId].file.length">
                <hr />
                <a :href="modalContents[modalId].file[0].path_download" target="_blank">
                  <img :src="modalContents[modalId].file[0].path_icon" alt="PDF Icon" class="pdf-icon" />
                  {{ modalContents[modalId].file[0].name }}
                </a>
                <p>Type: {{ modalContents[modalId].file[0].file_type.type }} | Size: {{ modalContents[modalId].file[0].file_size }} | Download: {{ modalContents[modalId].file[0].download }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Cookie Consent Banner -->
    <div v-if="showCookieConsent" id="cookie-consent-container" v-html="cookieConsentContent"></div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, nextTick } from "vue";
import axios from "axios";
import "animate.css";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "vuex";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default {
  name: "AppFooter",
  setup() {
    const showOntopButton = ref(false);
    const store = useStore();
    const homeData = computed(() => store.getters.homeData);
    const modalContents = ref({});
    const modals = ["modal-92", "modal-93", "modal-94", "modal-2022373"];
    const showCookieConsent = ref(false);
    const cookieConsentContent = ref("");

    const fetchData = async () => {
      await store.dispatch("fetchHomeData");
    };

    onMounted(() => {
      fetchData();
    });

    const scrollHandler = () => {
      showOntopButton.value = window.scrollY > 100;
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const getIcon = (name) => {
      return require(`@/assets/images/${name}.png`);
    };

    const fetchPolicies = async () => {
      const response = await fetch(`https://api.dmcr.go.th/webservice/dmcr_pdpd/policy`);
      const data = await response.json();
      modalContents.value = {
        "modal-92": data["92"],
        "modal-93": data["93"],
        "modal-94": data["94"],
        "modal-2022373": data["2022373"],
      };
    };

    const showModal = (policyId) => {
      const modalId = `modal-${policyId}`;
      const modalElement = document.getElementById(modalId);
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    };

    const fetchCookieConsent = async () => {
      try {
        const response = await axios.get("https://api.dmcr.go.th/webservice/dmcr_pdpd/viewcookieconsentnew");
        cookieConsentContent.value = response.data;
        showCookieConsent.value = true;

        await nextTick();
        // Event delegation to handle the button click inside dynamically loaded HTML
        const container = document.getElementById("cookie-consent-container");
        if (container) {
          container.addEventListener("click", (event) => {
            if (event.target && event.target.id === "acceptCookie") {
              acceptCookieConsent();
            } else if (event.target && event.target.id === "turnOff") {
              showCookieConsent.value = false;
            }
          });
        }
      } catch (error) {
        console.error("Error fetching cookie consent content:", error);
      }
    };

    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const setCookie = (name, value, days) => {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };

    const acceptCookieConsent = () => {
      setCookie("userConsent", "true", 365);
      showCookieConsent.value = false;
    };

    onMounted(() => {
      window.addEventListener("scroll", scrollHandler);
      fetchPolicies();

      if (!getCookie("userConsent")) {
        fetchCookieConsent();
      }
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", scrollHandler);
    });

    return {
      showOntopButton,
      scrollToTop,
      faArrowUp,
      homeData,
      getIcon,
      showModal,
      modalContents,
      modals,
      showCookieConsent,
      cookieConsentContent,
      acceptCookieConsent,
    };
  },
};
</script>

<style scoped>
.footer {
  padding-top: 20px;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
}

.footer-logo {
  width: 60px;
}

.footer-text-container {
  margin-left: 18px;
}

.social-icon {
  width: 40px;
  height: 40px;
}

.text-muted {
  font-weight: 300;
  font-size: 16px;
  color: #6c757d;
}

.text-foot {
  font-size: 19px;
}

.social-icons {
  flex-wrap: wrap;
}

.social-icon-item {
  margin-left: 1px;
}

.social-icon-item:first-child {
  margin-left: 0;
}

.policy-list a {
  color: #6c757d;
  font-weight: 400;
}

.policy-list a:hover {
  color: #007bff;
  text-decoration: none;
}

.policy-list li:not(:last-child)::after {
  content: "|";
  margin-left: 10px;
  margin-right: 10px;
  color: #6c757d;
}

.policy-container {
  background-color: #f1f1f1;
}

.modal-header {
  border: none;
}

.modal-title {
  font-weight: bold;
  color: #0065cc;
}

.pdf-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* Ontop Button Styles */
.ontop-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.ontop-button:hover {
  background-color: #0065cc;
  transform: scale(1.1);
}

.ontop-button i {
  font-size: 24px;
}

/* Fixed bottom iframe */
.fixed-bottom-iframe {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 200px;
  border: none;
  z-index: 1000; /* Ensure it is above other elements */
}

/* Hide Ontop Button on Mobile */
@media (max-width: 768px) {
  .ontop-button {
    display: none;
  }
}
</style>
<style>
.-pdpa-new-v1 {
  text-align: left;
}
.-pdpa-new-v1 .pdpa-desc span {
  font-weight: 300 !important;
}
</style>
