<template>
  <div>
    <div class="container">
      <!-- จำนวนรายจังหวัด -->
      <div class="row align-items-center my-3">
        <div class="col-6">
          <h1 class="header text-primary text-start">จำนวนรายจังหวัด</h1>
        </div>
        <div class="col-6 text-end"></div>
      </div>
      <div class="row">
        <div class="col-12">
          <Carousel v-bind="settings" :breakpoints="breakpointsProvince">
            <Slide v-for="(item, index) in homeData.percentage" :key="index">
              <div class="carousel__item">
                <StatCircle :name="item.name" :percentage="item.percentage" :people="item.people" />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <!-- ประกาศ -->
    <div class="container">
      <div class="row align-items-center my-3">
        <div class="col-6">
          <h1 class="header text-primary text-start">ประกาศ</h1>
        </div>
        <div class="col-6 text-end">
          <router-link to="/notifications" class="button button-secondary">ดูทั้งหมด</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Carousel v-bind="settings" :breakpoints="breakpointsAnnounce" :autoplay="4000">
            <Slide v-for="(item, index) in homeData.noti" :key="index">
              <div class="carousel_announce" :class="['carousel__item', getAnnounceClass(index)]">
                <div class="announce-content">
                  <div class="icon-circle">
                    <svg-icon :fa-icon="faBullhorn" :size="16" class="icon-announcement"></svg-icon>
                  </div>
                  <router-link :to="item.url" class="announce-text">{{ item.title }}</router-link>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
      <hr />
      <!-- ปฏิทินกิจกรรม -->
      <div class="row align-items-center my-3">
        <div class="col-6 text-start text-primary">
          <h1 class="header">ปฏิทินกิจกรรม</h1>
          <h4 class="sub-header">อาสาสมัครพิทักษ์ทะเล</h4>
        </div>
        <div class="col-6 text-end">
          <router-link to="/activities" class="button button-secondary">ดูทั้งหมด</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Carousel v-bind="settings" :breakpoints="breakpointsActivity">
            <Slide v-for="(item, index) in homeData.activity" :key="index">
              <div class="carousel__item">
                <div class="image-container">
                  <img :src="item.image" class="image" />
                  <div class="text-overlay">
                    <div class="text">{{ item.title }}</div>
                    <router-link :to="item.url" class="read-more">อ่านต่อ</router-link>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
      <hr />
    </div>
    <div class="background-content">
      <div class="container">
        <!-- ผลงานเด่น -->
        <div class="row align-items-center my-3">
          <div class="col-6 text-start text-primary">
            <h1 class="header">ผลงานเด่น</h1>
            <h4 class="sub-header">อาสาสมัครพิทักษ์ทะเล</h4>
          </div>
          <div class="col-6 text-end">
            <router-link to="/highlights" class="button button-secondary">ดูทั้งหมด</router-link>
          </div>
        </div>
        <div class="row row_featuredwork">
          <div class="col-12">
            <Carousel v-bind="settings" :breakpoints="breakpointsFeaturedWork">
              <Slide v-for="(item, index) in homeData.highlight" :key="index">
                <router-link :to="item.url" class="carousel__item">
                  <div class="circle-container">
                    <img :src="item.image" class="circle-image" />
                  </div>
                  <div class="text">{{ item.title }}</div>
                </router-link>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
        <hr />
        <!-- สิทธิประโยชน์ -->
        <div class="row align-items-center my-3">
          <div class="col-6 text-start text-primary">
            <h1 class="header">สิทธิประโยชน์</h1>
            <h4 class="sub-header">อาสาสมัครพิทักษ์ทะเล</h4>
          </div>
          <div class="col-6 text-end">
            <router-link to="/privileges" class="button button-secondary">ดูทั้งหมด</router-link>
          </div>
        </div>
        <div class="row row_benefits">
          <div class="col-12">
            <Carousel v-bind="settings" :breakpoints="breakpointsBenefits">
              <Slide v-for="(item, index) in homeData.privilege" :key="index">
                <router-link :to="item.url" class="carousel__item">
                  <div class="circle-container">
                    <img :src="item.image" class="circle-image" />
                  </div>
                  <div class="text">{{ item.title }}</div>
                </router-link>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
        <hr />
        <!-- ข่าวสาร -->
        <div class="row align-items-center my-3">
          <div class="col-6 text-start text-primary">
            <h1 class="header">ข่าวสาร</h1>
            <h4 class="sub-header">กรมทรัพยากรทางทะเลและชายฝั่ง</h4>
          </div>
          <div class="col-6 text-end">
            <router-link to="/news" class="button button-secondary">ดูทั้งหมด</router-link>
          </div>
        </div>
        <div class="row row_news">
          <div v-for="(item, index) in homeData.newsData" :key="index" :class="getNewsClass(index)">
            <div class="news-container">
              <a :href="item.link" target="_blank">
                <div :class="['news-image-container', getNewsImageContainerClass(index)]">
                  <img :src="item.image" class="news-image" />
                </div>
                <div class="text-start" :class="getNewsHeightClass(index)">
                  <div class="news-date">{{ item.date }}</div>
                  <div class="news-title">
                    {{ item.title }}
                  </div>
                  <div class="news-description">
                    {{ item.description }}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Register and Login Section -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 register-section">
          <div class="signup-section p-4 text-center">
            <h2>สมัครสมาชิก</h2>
            <p>อาสาสมัครพิทักษ์ทะเล<br />กรมทรัพยากรทางทะเลและชายฝั่ง</p>
            <router-link to="/terms" class="button button-secondary button-lg">สมัครสมาชิก</router-link>
          </div>
        </div>
        <div class="col-md-6 login-section" id="login">
          <div class="login-content p-4 text-center">
            <h2>
              ตรวจสอบสถานภาพ และเข้าสู่ระบบ<br />
              ข้อมูลอาสาสมัครพิทักษ์ทะเลของท่าน
            </h2>
            <form @submit.prevent="login">
              <div class="form-group">
                <input type="text" class="form-control text-center" id="idCard" v-model="idCard" placeholder="หมายเลขบัตรประชาชน" @input="validateIdCard" maxlength="13" />
                <div v-if="idCardError" class="text-danger">{{ idCardError }}</div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control text-center" id="birthdate" v-model="birthdate" placeholder="วันเดือนปีเกิด" @input="validateBirthdate" maxlength="8" />
                <small class="form-text text-muted">วัน-เดือน-ปี ตัวอย่างเช่น : 31052550</small>
                <div v-if="birthdateError" class="text-danger">{{ birthdateError }}</div>
              </div>
              <button type="submit" class="button button-secondary button-lg">เข้าสู่ระบบ</button>
            </form>
            <div v-if="loading" class="loading-overlay">
              <div class="spinner"></div>
            </div>
            <div v-if="!homeLoaded" class="loading-overlay loading-overlay-hide">
              <div class="spinner"></div>
            </div>
            <div v-if="loginMessage" class="alert alert-info mt-3">{{ loginMessage }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Tabs Section -->
    <div class="container my-5 d-none d-sm-block">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="central-tab" data-bs-toggle="tab" data-bs-target="#central" type="button" role="tab" aria-controls="central" aria-selected="true">
            <img src="@/assets/images/tel.png" />
            ติดต่อ ทช. ส่วนกลาง
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="gulf-tab" data-bs-toggle="tab" data-bs-target="#gulf" type="button" role="tab" aria-controls="gulf" aria-selected="false">
            <img src="@/assets/images/tel.png" />
            ติดต่อ ทช. ทะเลฝั่งอ่าวไทย
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="andaman-tab" data-bs-toggle="tab" data-bs-target="#andaman" type="button" role="tab" aria-controls="andaman" aria-selected="false">
            <img src="@/assets/images/tel.png" />
            ติดต่อ ทช. ทะเลฝั่งอันดามัน
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="other-tab" data-bs-toggle="tab" data-bs-target="#other" type="button" role="tab" aria-controls="other" aria-selected="false">
            <img src="@/assets/images/tel.png" />
            ติดต่อเรา
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="central" role="tabpanel" aria-labelledby="central-tab">
          <div class="table-responsive">
            <tel-table :data="getGroupData('ติดต่อ ทช. ส่วนกลาง')"></tel-table>
          </div>
        </div>
        <div class="tab-pane fade" id="gulf" role="tabpanel" aria-labelledby="gulf-tab">
          <div class="table-responsive">
            <tel-table :data="getGroupData('ติดต่อ ทช. ทะเลฝั่งอ่าวไทย')"></tel-table>
          </div>
        </div>
        <div class="tab-pane fade" id="andaman" role="tabpanel" aria-labelledby="andaman-tab">
          <div class="table-responsive">
            <tel-table :data="getGroupData('ติดต่อ ทช. ทะเลฝั่งอันดามัน')"></tel-table>
          </div>
        </div>
        <div class="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
          <div class="container mt-5">
            <form @submit.prevent="handleSubmit" style="text-align: left">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="group">เลือกประเภทการติดต่อ <font color="red">*</font></label>
                    <select v-model="form.group" id="group" class="form-control" :class="{ 'is-invalid': errors.group }">
                      <option disabled value="">เลือกประเภทการติดต่อ</option>
                      <option value="1">แจ้งเรื่องร้องเรียน</option>
                      <option value="2">ติดต่อเรา</option>
                    </select>
                    <div v-if="errors.group" class="invalid-feedback">{{ errors.group }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="subject">หัวข้อ <font color="red">*</font></label>
                    <input type="text" v-model="form.subject" id="subject" class="form-control" :class="{ 'is-invalid': errors.subject }" />
                    <div v-if="errors.subject" class="invalid-feedback">{{ errors.subject }}</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="message">ข้อความ <font color="red">*</font></label>
                <textarea v-model="form.message" id="message" class="form-control" rows="4" :class="{ 'is-invalid': errors.message }"></textarea>
                <div v-if="errors.message" class="invalid-feedback">{{ errors.message }}</div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">ชื่อ-นามสกุล <font color="red">*</font></label>
                    <input type="text" v-model="form.name" id="name" class="form-control" :class="{ 'is-invalid': errors.name }" />
                    <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="tel">เบอร์โทรศัพท์</label>
                    <input type="text" v-model="form.tel" id="tel" class="form-control" :class="{ 'is-invalid': errors.tel }" />
                    <div v-if="errors.tel" class="invalid-feedback">{{ errors.tel }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="citizen_id">เลขประจำตัวประชาชน</label>
                    <input type="text" v-model="form.citizen_id" id="citizen_id" class="form-control" :class="{ 'is-invalid': errors.citizen_id }" @input="validateCitizenId" maxlength="13" />
                    <div v-if="errors.citizen_id" class="invalid-feedback">{{ errors.citizen_id }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">อีเมล</label>
                    <input type="email" v-model="form.email" id="email" class="form-control" :class="{ 'is-invalid': errors.email }" />
                    <small class="text-muted">* หมายเหตุ: หากไม่มีอีเมลไม่ต้องกรอก</small>
                    <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="address">ที่อยู่</label>
                    <textarea v-model="form.address" id="address" class="form-control" rows="4" :class="{ 'is-invalid': errors.address }"></textarea>
                    <div v-if="errors.address" class="invalid-feedback">{{ errors.address }}</div>
                  </div>
                </div>
              </div>
              <div class="form-group text-center d-flex justify-content-center">
                <button type="submit" class="button button-contact button-secondary mx-2">ยืนยันและส่งข้อมูล</button>
                <button type="reset" class="button button-contact button-secondary mx-2" @click="resetForm">ล้างฟอร์ม</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import StatCircle from "../components/StatCircle.vue";
import "vue3-carousel/dist/carousel.css";
import TelTable from "../components/TelTable.vue";
import { showAlert } from "@/utils/utils";

export default defineComponent({
  name: "HomePage",
  setup() {
    const idCard = ref("");
    const birthdate = ref("");
    const idCardError = ref("");
    const birthdateError = ref("");
    const loading = ref(false);
    const loginMessage = ref("");
    const router = useRouter();

    const store = useStore();
    const homeData = computed(() => store.getters.homeData);
    const homeLoaded = computed(() => store.getters.homeLoaded);

    const fetchData = async () => {
      await store.dispatch("fetchHomeData");
    };

    onMounted(() => {
      fetchData();
    });

    const validateIdCard = () => {
      if (!/^\d{0,13}$/.test(idCard.value)) {
        idCard.value = idCard.value.slice(0, -1);
      }
    };

    const validateCitizenId = () => {
      if (!/^\d{0,13}$/.test(form.citizen_id)) {
        form.citizen_id = form.citizen_id.slice(0, -1);
      }
    };

    const validateBirthdate = () => {
      if (!/^\d{0,8}$/.test(birthdate.value)) {
        birthdate.value = birthdate.value.slice(0, -1);
      }
    };

    const form = reactive({
      group: "",
      subject: "",
      message: "",
      name: "",
      tel: "",
      email: "",
      citizen_id: "",
      address: "",
    });

    const errors = reactive({});

    const handleSubmit = async () => {
      loading.value = true;
      if (validateForm()) {
        try {
          await axios
            .post(`${process.env.VUE_APP_API_URL}/contact-us`, form)
            .then((response) => {
              if (response.status == 200) {
                showAlert({ title: `ส่งข้อมูลเรียบร้อยแล้ว`, icon: "success" });
                resetForm();
              } else {
                showAlert({ title: response.data.message, icon: "error" });
              }
            })
            .catch((error) => {
              if (error.response) {
                showAlert({ title: error.response.data.message, icon: "error" });
              } else {
                showAlert({ title: error.message, icon: "error" });
              }
            });
        } catch (error) {
          showAlert({ title: error, icon: "error" });
        }
      }
      loading.value = false;
    };

    const validateForm = () => {
      Object.keys(errors).forEach((key) => {
        errors[key] = "";
      });

      let valid = true;

      if (!form.group) {
        errors.group = "กรุณาเลือกประเภทการติดต่อ";
        valid = false;
      }

      if (!form.subject) {
        errors.subject = "กรุณากรอกหัวข้อ";
        valid = false;
      }

      if (!form.message) {
        errors.message = "กรุณากรอกข้อความ";
        valid = false;
      }

      if (!form.name) {
        errors.name = "กรุณากรอกชื่อ-นามสกุล";
        valid = false;
      }

      return valid;
    };

    const resetForm = () => {
      form.group = "";
      form.subject = "";
      form.message = "";
      form.name = "";
      form.tel = "";
      form.email = "";
      form.citizen_id = "";
      form.address = "";
      Object.keys(errors).forEach((key) => {
        errors[key] = "";
      });
    };

    const login = () => {
      let valid = true;

      if (idCard.value.length !== 13) {
        idCardError.value = "กรุณากรอกหมายเลขบัตรประชาชนต้องมี 13 หลัก";
        valid = false;
      } else {
        idCardError.value = "";
      }

      if (birthdate.value.length !== 8) {
        birthdateError.value = "กรุณากรอกวันเดือนปีเกิดต้องมี 8 หลัก";
        valid = false;
      } else {
        birthdateError.value = "";
      }

      if (!valid) {
        if (!idCard.value) {
          document.getElementById("idCard").focus();
        } else {
          document.getElementById("birthdate").focus();
        }
        return;
      }

      loading.value = true;
      loginMessage.value = "";

      axios
        .post(`${process.env.VUE_APP_API_URL}/login-web`, { username: idCard.value, password: birthdate.value })
        .then((response) => {
          loading.value = false;
          if (response.status == 200) {
            localStorage.setItem("authTokenWeb", response.data.token);
            localStorage.setItem("userInfoWeb", JSON.stringify(response.data.data));
            localStorage.setItem("needsSurvey", JSON.stringify(response.data.needs_survey));
            router.push("/profile");
          } else {
            loginMessage.value = response.data.message;
          }
        })
        .catch((error) => {
          loading.value = false;
          if (error.response) {
            loginMessage.value = error.response.data.message;
          } else {
            loginMessage.value = error.message;
          }
        });
    };

    const getGroupData = (groupName) => {
      if (homeData.value && homeData.value.telGroups) {
        const group = homeData.value.telGroups.find((group) => group.name === groupName);
        return group ? group.data : [];
      }
      return [];
    };

    return {
      faBullhorn,
      idCard,
      birthdate,
      idCardError,
      birthdateError,
      loading,
      loginMessage,
      validateIdCard,
      validateCitizenId,
      validateBirthdate,
      login,
      homeData,
      homeLoaded,
      getGroupData,
      handleSubmit,
      resetForm,
      form,
      errors,
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    StatCircle,
    TelTable,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    breakpointsProvince: {
      700: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      1199: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    },
    breakpointsAnnounce: {
      700: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
    breakpointsActivity: {
      700: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
    breakpointsFeaturedWork: {
      700: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 5,
        snapAlign: "center",
      },
    },
    breakpointsBenefits: {
      700: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 5,
        snapAlign: "center",
      },
    },
    breakpointsNews: {
      700: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
  }),
  methods: {
    getAnnounceClass(index) {
      const classes = ["bg-color-1", "bg-color-2", "bg-color-3"];
      return classes[index % 3];
    },
    getNewsClass(index) {
      return index % 3 === 0 ? "col-12 col-md-6" : "col-12 col-md-3";
    },
    getNewsHeightClass(index) {
      return index === 0 ? "news-item-1" : "news-item-other";
    },
    getNewsImageContainerClass(index) {
      return index === 0 ? "news-image-container-1" : "news-image-container-other";
    },
  },
});
</script>

<style scoped>
hr {
  color: #eee;
}

.header {
  font-size: 18px;
  margin: 0;
}

.sub-header {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.button {
  float: right;
}

.button-contact {
  float: none;
}

.carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 100%;
}

.carousel_announce {
  max-width: 300px;
}

.announce-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
  width: 100%;
  border-radius: 25px;
  text-align: center;
  color: white;
  padding: 0 10px;
  gap: 10px;
}

.icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.icon-announcement {
  font-size: 20px;
  color: currentColor;
}

.announce-text {
  flex: 1;
  color: inherit;
  text-decoration: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-align: left;
}

/* Background colors for announcements */
.bg-color-1 {
  background: linear-gradient(0deg, rgba(255, 152, 23, 1) 0%, rgba(255, 175, 75, 1) 55%, rgba(255, 152, 23, 1) 100%);
  border-radius: 25px;
  cursor: pointer;
}

.bg-color-2 {
  background: linear-gradient(0deg, rgba(50, 62, 184, 1) 0%, rgba(50, 100, 184, 1) 50%, rgba(50, 62, 184, 1) 100%);
  border-radius: 25px;
  cursor: pointer;
}
.bg-color-3 {
  background: linear-gradient(0deg, rgba(255, 89, 23, 1) 0%, rgba(255, 156, 75, 1) 50%, rgba(255, 89, 23, 1) 100%);
  border-radius: 25px;
  cursor: pointer;
}
.bg-color-1 svg {
  color: rgba(255, 152, 23, 1);
}
.bg-color-2 svg {
  color: rgba(50, 62, 184, 1);
}
.bg-color-3 svg {
  color: rgba(255, 89, 23, 1);
}

.carousel__item:hover .icon-circle {
  animation: pulse 0.6s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

/* Styles for the calendar items */
.image-container {
  position: relative;
  width: 350px;
  height: 240px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  color: #4284e5;
  text-align: center;
  padding: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: background 0.3s, color 0.3s, height 0.3s, border-radius 0.3s;
  overflow: hidden;
  height: 42%;
}

.image-container:hover .text-overlay {
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.read-more {
  display: none;
  margin-top: 10px;
  color: white;
  background: #037ee5;
  border: solid 1px #1365cb;
  width: 100px;
  padding: 4px 22px;
  font-weight: 500;
  text-align: center;
}

.image-container:hover .read-more {
  display: block;
  margin: 10px auto 0;
}

/* Styles for the featured work and benefits items */
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.carousel__item:hover .circle-container {
  animation: pulse 0.6s infinite;
}

.text {
  text-align: center;
  margin-top: 18px;
  font-size: 18px;
  color: #33349a;
  line-height: 1.2em;
  max-height: 3.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

a.carousel__item {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row_featuredwork .carousel__slide,
.row_benefits .carousel__slide {
  align-items: flex-start;
}

.background-content {
  background: url("@/assets/images/home-block-2.jpg") no-repeat center top;
  background-size: cover;
  padding-bottom: 30px;
}

/* Styles for the news items */
.news-container {
  position: relative;
}

.news-container a {
  text-decoration: unset;
}

.news-item {
  background: white;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.news-item-1,
.news-item-other {
  position: relative;
  background: white;
  padding: 8px;
}

.news-item-1 {
  height: 120px;
}

.news-item-other {
  height: 240px;
}

.news-image-container-1 img {
  height: 300px;
}

.news-image-container-other img {
  height: 180px;
}

.news-image {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.news-container:hover .news-image {
  transform: scale(1.1);
}

.news-image-container {
  position: relative;
  overflow: hidden;
}

.news-image-container img {
  transition: transform 0.3s ease-in-out;
}

.news-image-container:hover img {
  transform: scale(1.1); /* ปรับขนาดภาพเมื่อ hover */
}

.news-date {
  background: #037ee5;
  color: white;
  padding: 5px 14px;
  display: inline-block;
  position: absolute;
  top: -34px;
  left: 0px;
}

.news-title {
  font-size: 18px;
  font-weight: bold;
}

.news-title a {
  text-decoration: none;
  color: #037ee5;
}

.news-description {
  margin-top: 10px;
  color: #33349a;
}

.square-image {
  width: 100%;
  height: auto;
}

.text-container {
  background: white;
  padding: 10px;
  text-align: center;
  height: 60px;
  overflow: hidden;
}

.news-text {
  font-size: 14px;
  color: #33349a;
  line-height: 1.2em;
  max-height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news-item-1 .news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-text-fill-color: #037ee5;
}

.news-item-1 .news-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-text-fill-color: #33349a;
}

.news-item-other .news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-text-fill-color: #037ee5;
}

.news-item-other .news-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  -webkit-text-fill-color: #33349a;
}

/* Full-width background section */
.full-width-bg {
  width: 100%;
  display: flex;
  position: relative;
}

.register-section,
.login-section {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.register-section {
  background-image: url("../assets/images/wg-register.jpg");
}

.login-section {
  background-image: url("../assets/images/wg-login.jpg");
}

.signup-section {
  color: white;
  text-align: center;
}
.signup-section h2,
.signup-section p {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.login-content {
  color: #037ee5;
  text-align: center;
}

.signup-section h2 {
  font-size: 40px;
  margin-bottom: 10px;
  font-weight: 500;
}

.login-content h2 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 500;
}

.signup-section p {
  font-size: 18px;
  margin-bottom: 20px;
}

.signup-section .button,
.login-content .button {
  float: none;
  display: inline-flex;
}

.button-lg {
  padding: 12px 28px;
  font-size: 18px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 30px;
  border: 1px solid #ccc;
  text-align: center;
}

.form-control:focus {
  border-color: #037ee5;
  box-shadow: 0 0 5px rgba(3, 126, 229, 0.5);
}

/* Nav-tabs styles */
.nav-tabs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.nav-item {
  flex: 1;
  text-align: center;
}

.nav-link {
  width: 100%;
  border: none;
  border-radius: 0;
  color: gray;
}

.nav-link img {
  width: 26px;
  margin-top: -4px;
  -webkit-filter: grayscale(100%) brightness(100%);
  filter: grayscale(100%) brightness(100%);
}

.nav-link.active {
  border-bottom: 2px solid #037ee5;
  color: #037ee5;
}

.nav-link.active img {
  -webkit-filter: none;
  filter: none;
}

.tab-content {
  margin-top: 20px;
}

.login-content .text-danger {
  color: red;
  font-size: 0.875rem;
}

.alert {
  color: #571519;
  background-color: #edd4d4;
  border-color: #e6c3c3;
  padding: 10px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .header {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .news-item {
    height: 200px !important;
    margin-bottom: 15px !important;
  }

  .news-item-1,
  .news-item-other {
    height: 150px !important;
  }
  .news-image-container-1 img,
  .news-image-container-other img {
    height: 250px !important;
  }
  .news-title,
  .news-description {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
  }
  .news-title {
    -webkit-line-clamp: 1 !important;
  }
  .news-description {
    -webkit-line-clamp: 3 !important;
  }

  .news-container {
    margin-bottom: 16px;
  }
}
</style>
