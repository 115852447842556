<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container my-5">
    <h1 class="text-primary text-start">อาสาสมัครพิทักษ์ทะเล</h1>
    <div class="row mt-2">
      <div class="col-md-3 text-center">
        <img :src="profileImage" alt="Profile Image" class="img-fluid rounded-circle mb-3" />
        <template v-if="userInfo">
          <template v-if="profileData.is_approve != 1">
            <router-link to="/edit-profile" class="btn btn-lg custom-btn btn-primary my-2">แก้ไขโปรไฟล์</router-link>
          </template>
        </template>
        <button @click="logout" class="btn btn-lg custom-btn btn-primary w-100">ออกจากระบบ</button>
        <template v-if="userInfo">
          <div class="mt-3" v-if="profileData.is_approve == 1">
            <router-link to="/card-expire" class="btn btn-lg custom-btn btn-primary my-2">แจ้งต่ออายุบัตร</router-link>
            <router-link to="/card-disappear" class="btn btn-lg custom-btn btn-primary my-2">แจ้งบัตรหาย</router-link>
          </div>
        </template>
      </div>
      <div class="col-md-9 text-start information mt-3">
        <div class="my-2" v-for="(value, label, index) in formattedProfileData" :key="index">
          <strong>{{ label }}:</strong> <span>{{ value }}</span>
        </div>
      </div>
    </div>
    <template v-if="userInfo">
      <!-- Tabs for Status Check and Score History -->
      <ul class="nav nav-tabs mt-5">
        <li class="nav-item">
          <a class="nav-link" :class="{ active: activeTab === 'status' }" @click.prevent="activeTab = 'status'" href="#"> ตรวจสอบสถานะ </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: activeTab === 'scoreHistory' }" @click.prevent="activeTab = 'scoreHistory'" href="#"> ประวัติคะแนน </a>
        </li>
      </ul>

      <!-- Content based on the selected tab -->
      <div v-if="activeTab === 'status'" class="tab-content mt-3">
        <TimelineComponent :items="statusLog" />
      </div>
      <div v-else class="tab-content mt-3">
        <h2 class="text-primary text-start">คะแนนของคุณ {{ totalScore }} คะแนน</h2>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(entry, index) in scoreHistory" :key="index">
            <div class="score-box" :class="{ positive: entry.score > 0, negative: entry.score < 0 }">
              <strong>{{ entry.score > 0 ? "+ " : "- " }}{{ Math.abs(entry.score) }}</strong> คะแนน
            </div>
            <div class="score-details d-flex justify-content-between w-100">
              <span class="description">{{ entry.title }}</span>
              <span class="date">{{ formatDateScore(entry.create_date) }}</span>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <h2 class="text-primary text-start mt-5">MY QR CODE</h2>
    <div class="text-center">
      <canvas ref="qrcodeCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import QRCode from "qrcode";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { isLoggedIn, isLoggedInAdmin } from "@/auth";
import TimelineComponent from "@/components/TimelineComponent.vue";
import { months } from "@/data/stores";
import defaultProfileImage from "@/assets/images/placeholder.jpg";
import { showAlert } from "@/utils/utils";

export default {
  name: "ProfilePage",
  components: {
    TimelineComponent,
  },
  setup() {
    const profileData = ref({});
    const statusLog = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const userInfo = getUserInfo();
    const authTokenAdmin = getAuthTokenAdmin();
    const data = ref(null);
    const activeTab = ref("status");
    const scoreHistory = ref([]);

    onMounted(async () => {
      loading.value = true;
      const citizenId = route.query.citizenId;
      const needsSurvey = getneedsSurvey();
      const skipSurvey = getItemWithExpiration("skipSurvey");

      // ตรวจสอบว่าต้องการให้ไปที่หน้า Survey หรือไม่
      if (needsSurvey && skipSurvey == null) {
        router.push("/survey");
        return;
      }

      if (citizenId) {
        if (userInfo && userInfo.citizen_id === citizenId) {
          data.value = userInfo;
        } else if (authTokenAdmin) {
          await handleAdminAuth(citizenId, authTokenAdmin);
        } else {
          redirectToPermissionCheck(citizenId);
          return;
        }
      } else if (userInfo) {
        data.value = userInfo;
      } else {
        router.push("/");
        return;
      }

      if (data.value) {
        loadProfileData(data.value);
        loadStatusLog();
        generateQRCode();
        await loadScoreHistory();
      }
      loading.value = false;
    });

    function getItemWithExpiration(key) {
      const itemStr = localStorage.getItem(key);

      // ถ้าไม่มีข้อมูลใน localStorage ก็ return null
      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();

      // ถ้าเวลาหมดอายุแล้วก็ลบข้อมูลออก และ return null
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }

      return item.value;
    }

    function getneedsSurvey() {
      if (isLoggedIn()) {
        return localStorage.getItem("needsSurvey") ? JSON.parse(localStorage.getItem("needsSurvey")) : null;
      } else {
        return null;
      }
    }

    function getUserInfo() {
      if (isLoggedIn()) {
        return localStorage.getItem("userInfoWeb") ? JSON.parse(localStorage.getItem("userInfoWeb")) : null;
      } else {
        return null;
      }
    }

    function getAuthTokenAdmin() {
      if (isLoggedInAdmin()) {
        return localStorage.getItem("authTokenAdmin") ? localStorage.getItem("authTokenAdmin") : null;
      } else {
        return null;
      }
    }

    async function handleAdminAuth(citizenId, authTokenAdmin) {
      const decodedToken = jwt_decode(authTokenAdmin);
      const currentTime = Date.now() / 1000;

      if (currentTime > decodedToken.exp) {
        clearAuthData();
        redirectToPermissionCheck(citizenId);
      } else {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/getProfile/${citizenId}`, {
            headers: { Authorization: `Bearer ${authTokenAdmin}` },
          });
          data.value = response.data;
        } catch (error) {
          router.push(`/`);
        }
      }
    }

    function clearAuthData() {
      localStorage.removeItem("authTokenAdmin");
      localStorage.removeItem("userInfoWeb");
      localStorage.removeItem("needsSurvey");
      localStorage.removeItem("menu");
    }

    function redirectToPermissionCheck(citizenId) {
      router.push(`/permission-check?citizenId=${citizenId}`);
    }

    const loadProfileData = (data) => {
      profileData.value = data;
    };

    const loadStatusLog = async () => {
      const token = localStorage.getItem("authTokenWeb");
      if (token) {
        loading.value = true;
        try {
          const response = await axios({
            method: "get",
            url: `${process.env.VUE_APP_API_URL}/user-status`,
            headers: { Authorization: `Bearer ${token}` },
          });
          statusLog.value = response.data.data.map((log) => ({
            id: log.id,
            code: log.code,
            label: log.title,
            date: formatDatetime(log.update_date),
            description: log.description,
            completed: log.completed,
            button:
              log.code == "009" && log.completed == 0
                ? {
                    text: "ยืนยันการรับบัตร",
                    action: () => {
                      handleAction(log.id);
                    },
                  }
                : null,
          }));
        } catch (error) {
          console.error("Error fetching status log:", error);
        }
        loading.value = false;
      }
    };

    const loadScoreHistory = async () => {
      const token = localStorage.getItem("authTokenWeb");
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/user-score`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching score history:", error);
        }
      }
    };

    const handleAction = async (id) => {
      if (!confirm("คุณต้องการยืนยันการรับบัตรใช่หรือไม่?")) {
        return;
      }

      const token = localStorage.getItem("authTokenWeb");
      loading.value = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/update-status-receive/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
        // Reload status log after successful update
        await loadStatusLog();
      } catch (error) {
        if (error.response) {
          showAlert({ title: `error.response.data.message`, icon: "error" });
        } else {
          showAlert({ title: `error.message`, icon: "error" });
        }
      }
      loading.value = false;
    };

    const logout = () => {
      loading.value = true;
      localStorage.removeItem("authTokenWeb");
      localStorage.removeItem("userInfoWeb");
      localStorage.removeItem("needsSurvey");
      clearAuthData();
      loading.value = false;
      router.push("/");
    };

    const calculateMembershipDuration = () => {
      if (!profileData.value.approve_date) return "-";

      const issueDate = new Date(profileData.value.approve_date);
      if (isNaN(issueDate)) return "-";

      const currentDate = new Date();
      const duration = new Date(currentDate - issueDate);
      const years = duration.getUTCFullYear() - 1970;
      const months = duration.getUTCMonth();
      const days = duration.getUTCDate() - 1;

      return `${years} ปี ${months} เดือน ${days} วัน`;
    };

    const generateQRCode = () => {
      const canvas = document.querySelector("canvas");
      let url = `${process.env.VUE_APP_URL}/profile?citizenId=${profileData.value.citizen_id}`;
      QRCode.toCanvas(
        canvas,
        url,
        {
          width: 400,
          errorCorrectionLevel: "H",
          margin: 4,
          color: {
            dark: "#206fbf",
            light: "#FFFFFF",
          },
        },
        function (error) {
          if (error) console.error(error);
        }
      );
    };

    const formatDate = (day, month, year) => {
      if (day == null || month == null || year == null) return "-";
      const formattedDay = day.toString().padStart(2, "0");
      const monthName = months.find((m) => m.id === parseInt(month, 10)).shortName;
      return `${formattedDay} ${monthName} ${year}`;
    };

    const formatDatetime = (dateString) => {
      if (!dateString) return "-";

      const [datePart, timePart] = dateString.split(" ");
      const [year, month, day] = datePart.split("-");
      const thaiYear = (parseInt(year, 10) + 543).toString();
      const monthName = months.find((m) => m.id === parseInt(month, 10)).shortName;

      const formattedDay = day.padStart(2, "0");

      if (timePart) {
        return `${formattedDay} ${monthName} ${thaiYear}, ${timePart} น.`;
      } else {
        return `${formattedDay} ${monthName} ${thaiYear}`;
      }
    };

    function calculateExpireDate(expireDate) {
      if (!expireDate) return "-";
      const currentDate = new Date();
      const expire = new Date(expireDate);
      const timeDiff = expire.getTime() - currentDate.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysDiff <= 0) {
        return `${formatDatetime(expireDate)} (บัตรหมดอายุแล้ว)`;
      } else {
        return formatDatetime(expireDate);
      }
    }

    const formattedProfileData = computed(() => ({
      "ชื่อ - นามสกุล": `${profileData.value.name} ${profileData.value.lastname}`,
      วันเดือนปีเกิด: formatDate(profileData.value.birth_day, profileData.value.birth_month, profileData.value.birth_year),
      ศาสนา: profileData.value.m_lookup_religion_name || "-",
      หมู่โลหิต: profileData.value.m_lookup_blood_name || "-",
      ที่อยู่: `${profileData.value.address_no || "-"} ซอย${profileData.value.soi || "-"} ถนน${profileData.value.road || "-"} ตำบล${profileData.value.m_subdistrict_name || "-"} อำเภอ${
        profileData.value.m_district_name || "-"
      } จังหวัด${profileData.value.m_province_name || "-"} ${profileData.value.zipcode || "-"}`,
      เบอร์โทรศัพท์: profileData.value.tel || "-",
      "อสทล. จังหวัด": profileData.value.m_desired_province_name || "-",
      "วันที่สมัครเป็น อสทล.": formatDatetime(profileData.value.create_date),
      "วันที่อนุมัติเป็น อสทล.":
        formatDatetime(profileData.value.approve_date) === "-" ? (profileData.value.is_approve == 0 ? "รอการอนุมัติ" : "กรุณาตรวจสอบข้อมูลอีกครั้ง") : formatDatetime(profileData.value.approve_date),
      "เป็นสมาชิก อสทล. มาแล้ว": calculateMembershipDuration(),
      วันที่บัตรอาสาหมดอายุ: calculateExpireDate(profileData.value.expire_card_date),
    }));

    const profileImage = computed(() => profileData.value.image || defaultProfileImage);
    const formatDateScore = (dateString) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString("th-TH", options);
    };
    const totalScore = computed(() => {
      return (scoreHistory.value || []).reduce((total, entry) => total + entry.score, 0);
    });
    return {
      profileData,
      statusLog,
      loading,
      logout,
      profileImage,
      formattedProfileData,
      generateQRCode,
      userInfo,
      activeTab,
      scoreHistory,
      formatDateScore,
      totalScore,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 1000px;
}

.information {
  font-size: 1.1rem;
  color: #666;
}

.information strong {
  font-weight: 500;
}

.information span {
  font-weight: 100;
}

.timeline {
  position: relative;
  margin-top: 2rem;
  padding-left: 4rem;
}

.timeline-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 44px;
  bottom: 0;
  left: -1.5rem;
  width: 2px;
  height: 100%;
  background: #e0e0e0;
}

.timeline-item {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 2rem;
}

.timeline-item:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 4px;
}

.timeline-item.last-item::before {
  display: none;
}

.timeline-icon {
  position: absolute;
  left: -3rem;
  top: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-icon.completed {
  border: 4px solid #e0e0e0;
  background: #58bd44;
}

.timeline-icon i {
  color: white;
}

.timeline-content {
  height: 90px;
}

.img-fluid.rounded-circle {
  border: 2px solid #037de4;
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.custom-btn {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(to bottom, #037de4 0%, #037de4 50%, #33349a 100%);
  font-size: 18px;
  background-size: auto 200%;
  border-radius: 40px;
  width: 100%;
  transition: background-position 0.4s;
  background-position: center bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-btn.btn-lg {
  height: 55px;
}

.custom-btn:hover {
  background: #037de4;
}
.list-group-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #fff;
  border: none;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  height: 70px;
}

.score-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 100%;
  color: #fff;
  border-radius: 10px;
  margin-right: 15px;
  text-align: center;
  flex-direction: column;
}

.score-box.positive {
  background-color: #58bd44;
}

.score-box.negative {
  background-color: #ff4c4c;
}

.score-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 95px);
  height: 100%;
}

.description {
  font-size: 1rem;
  color: #333;
}

.date {
  font-size: 1rem;
  color: #666;
  white-space: nowrap;
  margin-right: 30px;
}

@media (max-width: 576px) {
  .information {
    font-size: 1rem;
    padding-left: 40px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .timeline-item {
    flex-direction: column;
  }

  .timeline-content {
    text-align: center;
    height: 70px;
  }
}
</style>
