<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container my-5 text-start">
    <div class="row mb-2">
      <h1 class="col-12 text-primary text-header">{{ pageTitle }}</h1>
    </div>
    <div class="row mb-4">
      <div class="col-md-4 col-lg-2 mt-2">
        <v-select id="sort-select" v-model="selectedSort" :options="sortOptions" placeholder="จัดเรียงตาม" :reduce="(sortOption) => sortOption.value" label="text" @change="fetchData" />
      </div>
      <div class="col-6 col-md-4 col-lg-2 mt-2">
        <VueDatePicker
          v-model="startDate"
          :format="formatDate"
          :class="'custom-input'"
          model-type="yyyy-MM-dd"
          locale="th"
          :format-locale="th"
          placeholder="ระบุวันเริ่มต้น"
          :enable-time-picker="false"
          :action-row="{ showNow: true }"
          auto-apply
        >
          <template #year="{ value }">{{ value + 543 }}</template>
          <template #year-overlay-value="{ value }"> {{ value + 543 }} </template>
        </VueDatePicker>
      </div>
      <div class="col-6 col-md-4 col-lg-2 mt-2">
        <VueDatePicker
          v-model="endDate"
          :format="formatDate"
          :class="'custom-input'"
          model-type="yyyy-MM-dd"
          locale="th"
          :format-locale="th"
          placeholder="ระบุวันสิ้นสุด"
          :enable-time-picker="false"
          :action-row="{ showNow: true }"
          auto-apply
        >
          <template #year="{ value }">{{ value + 543 }}</template>
          <template #year-overlay-value="{ value }"> {{ value + 543 }} </template>
        </VueDatePicker>
      </div>
      <div class="col-12 col-lg-6 mt-2">
        <div class="input-group w-auto">
          <input type="text" class="form-control custom-input custom-input-text" v-model="searchQuery" placeholder="ค้นหา" />
          <button class="btn btn-primary" @click="fetchData"><i class="fas fa-search"></i></button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div v-for="(item, index) in data" :key="index" class="col-md-4 col-lg-3 mb-4">
            <div class="card h-100">
              <router-link :to="item.url">
                <img v-if="item.image" :src="item.image" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h6 class="text-primary">{{ formattedDate(item.show_date) }}</h6>
                  <div class="card-text">{{ item.title }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-4">
        <div class="text-total">ทั้งหมด {{ totalItems }} รายการ</div>
      </div>
      <div class="col-md-8 mt-4">
        <nav>
          <ul class="pagination">
            <li class="page-item d-none d-sm-block" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" @click="changePage(1)" :class="{ disabled: currentPage === 1 }">หน้าแรกสุด</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" @click="changePage(currentPage - 1)" v-if="currentPage > 1">&#8249;</a>
            </li>
            <li class="page-item" v-for="page in pagesToShow" :key="page" :class="{ active: currentPage === page }">
              <a class="page-link" @click="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" @click="changePage(currentPage + 1)" v-if="currentPage < totalPages">&#8250;</a>
            </li>
            <li class="page-item d-none d-sm-block" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" @click="changePage(totalPages)" :class="{ disabled: currentPage === totalPages }">หน้าสุดท้าย</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-12 mt-5 d-flex justify-content-center">
        <router-link to="/" class="button button-secondary">กลับ</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ref, computed, onMounted, watch } from "vue";
import { th } from "date-fns/locale";
import axios from "axios";
import { useRoute } from "vue-router";
import "vue-select/dist/vue-select.css";

export default {
  name: "CMSAllPage",
  components: { vSelect },
  setup() {
    const route = useRoute();
    const data = ref([]);
    const searchQuery = ref("");
    const currentPage = ref(1);
    const itemsPerPage = ref(8);
    const totalItems = ref(0);
    const loading = ref(false);
    const selectedSort = ref("");
    const sortOptions = ref([
      { text: "ใหม่ล่าสุด", value: "DESC" },
      { text: "เก่าสุด", value: "ASC" },
    ]);
    const startDate = ref("");
    const endDate = ref("");
    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear() + 543;

      return `${day}/${month}/${year}`;
    };
    const fetchData = async () => {
      loading.value = true;
      try {
        let group = "";
        if (route.path.startsWith("/noti")) {
          group = "1";
        } else if (route.path.startsWith("/highlight")) {
          group = "2";
        } else if (route.path.startsWith("/privilege")) {
          group = "3";
        }

        let endpoint = `${process.env.VUE_APP_API_URL}/cms-pages/${group}`;
        if (route.path.startsWith("/activities")) {
          endpoint = `${process.env.VUE_APP_API_URL}/activity-pages`;
        }
        const response = await axios.get(endpoint, {
          params: {
            per_page: itemsPerPage.value,
            page: currentPage.value,
            filter: searchQuery.value,
            sort: selectedSort.value,
            start_date: startDate.value,
            end_date: endDate.value,
          },
        });
        if (response.status === 200) {
          data.value = response.data.data;
          totalItems.value = response.data.total;
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
      document.querySelectorAll(".v-select .vs__dropdown-toggle").forEach((el) => {
        el.style.border = "none";
        el.style.boxShadow = "none";
        el.style.padding = "0";
      });
      document.querySelectorAll(".v-select .vs__clear").forEach((el) => {
        el.style.marginTop = "-3px";
      });
      document.querySelectorAll(".v-select .vs__search").forEach((el) => {
        el.style.fontSize = "13px";
      });

      document.querySelectorAll(".dp__input_icon_pad").forEach((el) => {
        el.style.fontFamily = "Kanit";
        el.style.fontSize = "0.84rem";
        el.style.border = "0";
      });

      document.querySelectorAll(".dp__theme_light").forEach((el) => {
        el.style.padding = "4px 8px";
      });
    });
    watch([currentPage, selectedSort, startDate, endDate], fetchData);

    const totalPages = computed(() => {
      return Math.ceil(totalItems.value / itemsPerPage.value);
    });

    const pagesToShow = computed(() => {
      const pages = [];
      const startPage = Math.max(1, currentPage.value - 2);
      const endPage = Math.min(totalPages.value, currentPage.value + 2);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    });

    const changePage = (page) => {
      if (page > 0 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const formattedDate = (date) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("th-TH", options);
    };

    const pageTitle = computed(() => {
      if (route.path.startsWith("/notifications")) {
        return "ประกาศ";
      } else if (route.path.startsWith("/highlights")) {
        return "ผลงานเด่น";
      } else if (route.path.startsWith("/privileges")) {
        return "สิทธิประโยชน์";
      } else if (route.path.startsWith("/activities")) {
        return "กิจกรรม";
      } else {
        return "ข้อมูล";
      }
    });

    return {
      data,
      searchQuery,
      currentPage,
      itemsPerPage,
      totalItems,
      loading,
      formatDate,
      fetchData,
      totalPages,
      changePage,
      th,
      formattedDate,
      pagesToShow,
      pageTitle,
      selectedSort,
      sortOptions,
      startDate,
      endDate,
    };
  },
};
</script>

<style scoped>
.text-header {
  font-size: 2rem;
}

.text-total {
  color: #037ee5;
  font-size: 20px;
}

.filter-button {
  border-radius: 20px;
}

.card {
  border-radius: 15px;
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card a {
  display: block;
  overflow: hidden;
  text-decoration: unset;
}

.card-img-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: transform 0.3s;
}

.card a:hover .card-img-top {
  transform: scale(1.05);
}

.card-body {
  padding: 15px;
}

.custom-input-text {
  padding: 10px 20px;
}

.custom-input {
  font-size: 0.9rem;
  border-radius: 50px;
  border: 1px solid #ccc;
}

.custom-input::placeholder {
  color: #888;
  font-size: 0.8em;
}

.custom-input:focus {
  border-color: #037de4;
  box-shadow: none;
}

.v-select {
  font-size: 0.9rem;
  border-radius: 50px;
  padding: 8px 20px;
  height: 44px;
  border: 1px solid #ccc;
}

.v-select .vs__search {
  padding: 0 10px;
}

.v-select .vs__selected {
  padding: 0 10px;
}

.v-select .vs__actions {
  margin-right: 10px;
}

.v-select.is-invalid {
  border-color: red;
}

.v-select.is-invalid .vs__dropdown-toggle {
  border-color: red;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
}

.page-item {
  margin: 0 5px;
}

.page-item.disabled .page-link {
  pointer-events: none;
  opacity: 0.5;
}

.page-item.active .page-link {
  background-color: #037ee5;
  border-color: #037ee5;
  color: white;
}

.page-link {
  color: #037ee5;
  border-radius: 5%;
  padding: 6px 12px;
  border: 2px solid #037ee5;
  cursor: pointer;
}

.page-link:hover {
  background-color: #035bb5;
  border-color: #035bb5;
  color: white;
}

.page-item .page-link[disabled] {
  cursor: not-allowed;
}

@media (max-width: 576px) {
  .pagination {
    justify-content: center;
  }
  .page-item.d-none {
    display: none !important;
  }
}
</style>
