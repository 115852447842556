<template>
  <div class="container my-5 text-start">
    <div class="row">
      <h1 class="col-12 text-primary text-header">ข้อกำหนดและเงื่อนไข</h1>
      <div class="mt-5 small-text font-weight-light" v-html="siteSettings.terms"></div>
      <div class="col-12 d-flex align-items-center small-text checkbox-control">
        <input type="checkbox" id="accept" class="me-2" v-model="accepted" />
        <label class="ms-3" for="accept">ข้าพเจ้าได้อ่านรับทราบและยอมรับข้อกำหนดทั้งหมดที่กล่าวมา</label>
        <span class="icon" :class="{ checked: accepted }"></span>
      </div>
      <hr class="d-none d-md-block" />
      <div class="col-12 d-flex justify-content-center mt-5">
        <router-link to="/register" class="button button-secondary me-5" :class="{ disabled: !accepted }"> ยอมรับ </router-link>
        <router-link to="/" class="button button-secondary">ไม่ยอมรับ</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "TermsAndConditionsPage",
  data() {
    return {
      accepted: false,
    };
  },
  setup() {
    const store = useStore();
    const siteSettings = computed(() => store.getters.siteSettings);

    const fetchData = async () => {
      await store.dispatch("fetchSiteSettings");
    };

    onMounted(() => {
      fetchData();
    });

    return {
      siteSettings,
    };
  },
};
</script>

<style scoped>
.small-text {
  font-size: 14px;
}

.font-weight-light {
  font-weight: 300;
}

.checkbox-control {
  margin-top: 20px;
  margin-bottom: 30px;
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 767px) {
  .checkbox-control {
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media (min-width: 1198px) {
  .container {
    max-width: 1000px;
  }

  .checkbox-control .icon {
    left: 0;
  }
}
</style>
