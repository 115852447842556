<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container">
    <div class="card" v-if="!registrationSuccess">
      <div class="card-body mb-5">
        <div class="text-center mb-4">
          <h3 class="card-title">ลงทะเบียนเข้าร่วมกิจกรรม</h3>
          <h4 class="card-title">{{ title }}</h4>
          <div class="card-text mt-3">{{ date }}</div>
          <div class="card-text">{{ time }}</div>
          <div class="card-text mt-1">{{ location }}</div>
        </div>
        <div v-if="!tokenAvailable">
          <div class="form-group mb-3">
            <label for="citizenId">หมายเลขบัตรประชาชน</label>
            <input
              type="text"
              class="form-control"
              id="citizenId"
              v-model="citizenId"
              placeholder="กรอกหมายเลขบัตรประชาชน"
              maxlength="13"
              @input="validateInput($event, 'citizenId', 13)"
              title="กรุณากรอกหมายเลขบัตรประชาชน 13 หลัก"
              ref="citizenIdInput"
            />
            <div v-if="citizenIdError" class="text-danger">{{ citizenIdError }}</div>
          </div>
          <div class="form-group mb-3">
            <label for="birthdate">วันเดือนปีเกิด</label>
            <input
              type="text"
              class="form-control"
              id="birthdate"
              v-model="birthdate"
              placeholder="กรอกวันเกิด (ตัวอย่าง: 30052534)"
              maxlength="8"
              @input="validateInput($event, 'birthdate', 8)"
              title="กรุณากรอกวันเกิด 8 หลัก (วันเดือนปีพุทธศักราช)"
              ref="birthdateInput"
            />
            <div v-if="birthdateError" class="text-danger">{{ birthdateError }}</div>
          </div>
        </div>
        <div class="text-center">
          <button @click="registerActivity" class="btn btn-primary w-50">ลงทะเบียน</button>
        </div>
        <div v-if="registrationMessage" :class="{ 'text-success': registrationSuccess, 'text-danger': !registrationSuccess }" class="mt-3 text-center">
          {{ registrationMessage }}
        </div>
      </div>
    </div>
    <div class="text-center" v-else>
      <h3 class="text-success">คุณได้ลงทะเบียนเรียบร้อยแล้ว!</h3>
      <div v-if="responseProfilePicture" class="mt-3">
        <img :src="responseProfilePicture" alt="Profile Picture" class="img-thumbnail" style="max-width: 150px" />
      </div>
      <p class="mt-3" style="font-size: 22px; margin-bottom: 65px">ชื่อ: {{ responseFirstName }} {{ responseLastName }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { showAlert } from "@/utils/utils";
import { isLoggedIn } from "@/auth";

export default {
  name: "RegisterActivityPage",
  setup() {
    const loading = ref(false);
    const activityId = ref("");
    const title = ref("");
    const date = ref("");
    const time = ref("");
    const location = ref("");
    const citizenId = ref("");
    const birthdate = ref("");
    const citizenIdError = ref("");
    const birthdateError = ref("");
    const registrationMessage = ref("");
    const registrationSuccess = ref(false);
    const responseFirstName = ref("");
    const responseLastName = ref("");
    const responseProfilePicture = ref("");
    const tokenAvailable = ref(false);
    const userInfo = getUserInfo();

    const citizenIdInput = ref(null);
    const birthdateInput = ref(null);

    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      activityId.value = route.query.id;
      if (activityId.value) {
        await fetchActivityData(activityId.value);
        if (userInfo) {
          tokenAvailable.value = true;
          await registerActivity();
        }
      } else {
        router.push("/");
      }
    });

    function getUserInfo() {
      if (isLoggedIn()) {
        return localStorage.getItem("userInfoWeb") ? JSON.parse(localStorage.getItem("userInfoWeb")) : null;
      } else {
        return null;
      }
    }

    const fetchActivityData = async (id) => {
      loading.value = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/register-activity/${id}`);
        if (response.status === 200) {
          const { title: activityTitle, date: activityDate, time: activityTime, location: activityLocation } = response.data;
          title.value = activityTitle;
          date.value = activityDate;
          time.value = activityTime;
          location.value = activityLocation;
        } else {
          handleError("ไม่สามารถโหลดข้อมูลกิจกรรมได้");
        }
      } catch (error) {
        handleError(error.response ? error.response.data.message : error.message);
      } finally {
        loading.value = false;
      }
    };

    const handleError = (message) => {
      registrationMessage.value = message;
      setTimeout(() => {
        showAlert({ title: message, icon: "error" });
      }, 1000);
      setTimeout(() => {
        router.push("/");
      }, 3000);
    };

    const validateInput = (event, field, length) => {
      const value = event.target.value.replace(/\D/g, "").slice(0, length);
      if (field === "citizenId") {
        citizenId.value = value;
      } else if (field === "birthdate") {
        birthdate.value = value;
      }
    };

    const registerActivity = async () => {
      if (!tokenAvailable.value) {
        if (citizenId.value.length !== 13) {
          citizenIdError.value = "กรุณากรอกหมายเลขบัตรประชาชนต้องมี 13 หลัก";
          citizenIdInput.value.focus();
          return;
        }
        citizenIdError.value = "";

        if (birthdate.value.length !== 8) {
          birthdateError.value = "กรุณากรอกวันเกิดต้องมี 8 หลัก";
          birthdateInput.value.focus();
          return;
        }
        birthdateError.value = "";
      }

      loading.value = true;
      registrationMessage.value = "";

      try {
        const payload = {
          id: activityId.value,
          username: citizenId.value,
          password: birthdate.value,
        };

        const token = localStorage.getItem("authTokenWeb");
        let response = null;
        if (token) {
          response = await axios.post(`${process.env.VUE_APP_API_URL}/regis-activity-qr`, payload, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } else {
          response = await axios.post(`${process.env.VUE_APP_API_URL}/regis-activity-qr`, payload);
        }
        if (response.status === 200) {
          registrationSuccess.value = true;
          registrationMessage.value = "ลงทะเบียนสำเร็จ!";
          responseFirstName.value = response.data.name;
          responseLastName.value = response.data.lastname;
          responseProfilePicture.value = response.data.image;
        } else {
          setTimeout(() => {
            showAlert({ title: response.data.message, icon: "error" });
          }, 1000);
        }
      } catch (error) {
        setTimeout(() => {
          showAlert({ title: error.response ? error.response.data.message : error.message, icon: "error" });
        }, 1000);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      title,
      date,
      time,
      location,
      citizenId,
      birthdate,
      citizenIdError,
      birthdateError,
      registrationMessage,
      registrationSuccess,
      validateInput,
      registerActivity,
      citizenIdInput,
      birthdateInput,
      responseFirstName,
      responseLastName,
      responseProfilePicture,
      tokenAvailable,
    };
  },
};
</script>
