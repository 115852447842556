export const menuDisplay = {
  "/": {
    title: "หน้าแรก",
    breadcrumb: [{ path: "/", text: "หน้าแรก", icon: "fas fa-home" }],
  },
  "/profile": {
    title: "อาสาสมัครพิทักษ์ทะเล",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/profile", text: "อาสาสมัครพิทักษ์ทะเล" },
    ],
  },
  "/permission-check": {
    title: "ยืนยันตัวตนสิทธิ",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/permission-check", text: "ยืนยันตัวตนสิทธิ" },
    ],
  },
  "/register-activity": {
    title: "ลงทะเบียนเข้าร่วมกิจกรรม",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/register-activity", text: "ลงทะเบียนเข้าร่วมกิจกรรม" },
    ],
  },
  "/terms": {
    title: "ข้อกำหนดและเงื่อนไข",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/terms", text: "ข้อกำหนดและเงื่อนไข" },
    ],
  },
  "/register": {
    title: "สมาชิกอาสาสมัครพิทักษ์ทะเล",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/register", text: "สมัครสมาชิกอาสาสมัครพิทักษ์ทะเล" },
    ],
  },
  "/edit-profile": {
    title: "ข้อมูลอาสาสมัครพิทักษ์ทะเล",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/edit-profile", text: "ข้อมูลอาสาสมัครพิทักษ์ทะเล" },
    ],
  },
  "/activities": {
    title: "กิจกรรม",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/activities", text: "กิจกรรม" },
    ],
  },
  "/notifications": {
    title: "ประกาศ",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/notifications", text: "ประกาศ" },
    ],
  },
  "/highlights": {
    title: "ผลงานเด่น",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/highlights", text: "ผลงานเด่น" },
    ],
  },
  "/privileges": {
    title: "สิทธิประโยชน์",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/privileges", text: "สิทธิประโยชน์" },
    ],
  },
  "/news": {
    title: "ข่าวสาร",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/news", text: "ข่าวสาร" },
    ],
  },
  "/noti/:id": {
    title: "ประกาศ",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/noti", text: "ประกาศ" },
    ],
  },
  "/highlight/:id": {
    title: "ผลงานเด่น",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/highlight", text: "ผลงานเด่น" },
    ],
  },
  "/privilege/:id": {
    title: "สิทธิประโยชน์",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/privilege", text: "สิทธิประโยชน์" },
    ],
  },
  "/survey": {
    title: "แบบทดสอบความพึงพอใจ",
    breadcrumb: [
      { path: "/", text: "หน้าแรก", icon: "fas fa-home" },
      { path: "/survey", text: "แบบทดสอบความพึงพอใจ" },
    ],
  },
};
