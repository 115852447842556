<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container my-5 text-start">
    <div class="row mb-2">
      <h1 class="col-12 text-primary text-header">แสดงข่าวสาร</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div v-for="(item, index) in data" :key="index" :class="getColumnClass(index)">
            <div class="card h-100">
              <a :href="item.link" target="_blank">
                <div :class="['image-container', { 'first-image-container': index === 0 }]">
                  <img v-if="item.image" :src="item.image" class="card-img-top" alt="..." />
                </div>
                <div class="card-body">
                  <h6 class="text-primary">{{ item.date }}</h6>
                  <div class="card-text">{{ item.title }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5 d-flex justify-content-center">
        <router-link to="/" class="button button-secondary">กลับ</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  name: "NewsPage",
  setup() {
    const data = ref([]);
    const loading = ref(false);
    const fetchData = async () => {
      loading.value = true;
      try {
        let endpoint = `${process.env.VUE_APP_API_URL}/news`;
        const response = await axios.get(endpoint, {});
        if (response.status === 200) {
          data.value = response.data;
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const getColumnClass = (index) => {
      return index === 0 ? "col-12 col-md-8 col-lg-6 mb-4" : "col-md-4 col-lg-3 mb-4";
    };

    return {
      data,
      loading,
      fetchData,
      getColumnClass,
    };
  },
};
</script>

<style scoped>
.text-header {
  font-size: 2rem;
}

.card {
  border-radius: 2px;
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card a {
  display: block;
  overflow: hidden;
  text-decoration: unset;
}

.image-container {
  position: relative;
  width: 100%;
  height: 200px; /* Set a fixed height for the container */
  overflow: hidden;
}

.first-image-container {
  height: 300px; /* Set a different height for the first image */
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.card a:hover .card-img-top {
  transform: scale(1.05);
}

.card-body {
  padding: 15px;
}
</style>
