import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import ProfilePage from "@/views/ProfilePage.vue";
import TermsAndConditionsPage from "@/views/TermsAndConditionsPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import PermissionCheckPage from "@/views/PermissionCheckPage.vue";
import RegisterActivityPage from "@/views/RegisterActivityPage.vue";
import CMSPage from "@/views/CMSPage.vue";
import CMSAllPage from "@/views/CMSAllPage.vue";
import NewsPage from "@/views/NewsPage.vue";
import SurveyPage from "@/views/SurveyPage.vue";
import { isLoggedIn } from "@/auth";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/terms",
    name: "TermsAndConditionsPage",
    component: TermsAndConditionsPage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/edit-profile",
    name: "EditProfilePage",
    component: RegisterPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/card-expire",
    name: "CardExpirePage",
    component: RegisterPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/card-disappear",
    name: "CardDisappearPage",
    component: RegisterPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/permission-check",
    name: "PermissionCheckPage",
    component: PermissionCheckPage,
  },
  {
    path: "/register-activity",
    name: "RegisterActivityPage",
    component: RegisterActivityPage,
  },
  {
    path: "/notifications",
    name: "notificationsPage",
    component: CMSAllPage,
  },
  {
    path: "/highlights",
    name: "highlightsPage",
    component: CMSAllPage,
  },
  {
    path: "/privileges",
    name: "privilegesPage",
    component: CMSAllPage,
  },
  {
    path: "/activities",
    name: "activitiesPage",
    component: CMSAllPage,
  },
  {
    path: "/news",
    name: "newsPage",
    component: NewsPage,
  },
  {
    path: "/noti/:id",
    name: "notiPage",
    component: CMSPage,
  },
  {
    path: "/highlight/:id",
    name: "highlightPage",
    component: CMSPage,
  },
  {
    path: "/privilege/:id",
    name: "privilegePage",
    component: CMSPage,
  },
  {
    path: "/activity/:id",
    name: "ActivityPage",
    component: CMSPage,
  },
  {
    path: "/survey",
    name: "SurveyPage",
    component: SurveyPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "HomePage" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

// ใช้ router guard เพื่อเช็คการ login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // เช็คว่าถ้าหน้านี้ต้องการการ login
    if (!isLoggedIn()) {
      // ถ้า user ไม่ได้ login ให้ redirect ไปหน้า home หรือหน้า login
      next({ name: "HomePage" });
    } else {
      // ถ้า login แล้วให้ไปหน้าที่ต้องการ
      next();
    }
  } else {
    // ถ้าหน้าไม่ต้องการการ login ให้ไปหน้าที่ต้องการได้เลย
    next();
  }
});

export default router;
