<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th nowrap scope="col">หน่วยงาน</th>
          <th nowrap scope="col">ที่อยู่</th>
          <th nowrap scope="col">แผนที่</th>
          <th nowrap scope="col">หมายเลขภายใน</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td class="text-left" nowrap>{{ item.name }}</td>
          <td>{{ item.location }}</td>
          <td nowrap>
            <template v-if="item.image">
              <a href="#" @click.prevent="showMap(item)"><i class="fas fa-map-marker-alt"></i></a>
            </template>
            <template v-else> - </template>
          </td>
          <td nowrap>
            <a :href="'tel:' + item.tel">{{ item.tel }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { showAlert } from "@/utils/utils";
export default {
  name: "TelTable",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showMap(data) {
      showAlert({
        title: `แผนที่${data.name}`,
        imageUrl: data.image,
        confirmButtonText: "ปิด",
        width: "60%",
      });
    },
  },
};
</script>

<style scoped>
.table-responsive {
  margin-top: 20px;
}

.table {
  width: 100%;
}

.table > :not(:first-child) {
  border-top: 1px solid gray;
}

.table th,
.table td {
  text-align: center;
}

.table td {
  font-weight: 100;
  padding-top: 14px;
  padding-bottom: 14px;
}

.table td a {
  color: #212529;
  text-decoration: none;
}

.table td a img {
  width: 20px;
}

.table th {
  color: #037ee5;
  font-weight: 500;
}
</style>
