<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container my-5 text-start">
    <div class="row">
      <h1 class="col-12 text-primary text-header">{{ pageTitle }}</h1>
      <hr class="mt-3 mb-4" />
      <div class="col-md-8 col-12 text-center text-md-start">
        <h2 class="text-blue m-0">{{ data.title }}</h2>
      </div>
      <div class="col-md-4 col-12 small-text d-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center text-blue mt-3 mt-md-0">
        <div class="me-0 me-md-3 mb-2 mb-md-0 d-flex align-items-center">
          <i class="fas fa-calendar-alt icon-circle"></i>
          <span class="ms-2">{{ formattedDate(data.show_date) }}</span>
        </div>
        <div class="d-flex align-items-center">
          <i class="fas fa-eye icon-circle"></i>
          <span class="ms-2">{{ formattedViews(data.view) }}</span>
        </div>
      </div>
      <div v-if="data.image" class="col-12 text-center mt-4">
        <viewer :images="data.image">
          <img :src="data.image" class="img-fluid" alt="Data Image" />
        </viewer>
      </div>
      <!-- Additional Information Section -->
      <div v-if="route.path.startsWith('/activity')" class="col-12 mt-4 text-light additional-info">
        <div class="row">
          <div class="col-md-4 mb-2">
            <i class="fas fa-calendar-alt"></i> <span>{{ formattedDate(data.start_date) }}</span>
            <div>
              <i class="fas fa-clock"></i> <span>{{ data.duration_type }}</span>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <i class="fas fa-map-marker-alt"></i> <span>{{ data.location }}</span>
          </div>
          <div class="col-md-4 mb-2">
            <i class="fas fa-user"></i> <span>{{ data.contact }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4 font-weight-light">
        <div v-html="data.detail"></div>
      </div>
      <div class="col-12 mt-4" v-if="data.album && data.album.length">
        <h3 class="text-blue">อัลบั้มภาพ</h3>
        <viewer :images="data.album">
          <div class="row">
            <div v-for="(img, index) in data.album" :key="index" class="col-6 col-md-4 col-lg-2 mb-3">
              <div class="image-container">
                <img :src="img" class="img-fluid" alt="Album Image" />
              </div>
            </div>
          </div>
        </viewer>
      </div>
      <div class="col-12 mt-4" v-if="data.files && data.files.length">
        <h3 class="text-blue">เอกสารดาวน์โหลด</h3>
        <div class="row">
          <div v-for="(file, index) in data.files" :key="index" class="col-12 col-md-6 col-lg-4 mb-3">
            <div class="file-container p-3 border rounded d-flex align-items-center">
              <i class="fas fa-download file-icon me-3"></i>
              <div class="w-100">
                <a :href="file.file" class="text-decoration-none" target="_blank">{{ file.name }}</a>
                <div class="d-flex justify-content-between">
                  <div class="small-text">ขนาดไฟล์: {{ file.size }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5 d-flex justify-content-center">
        <router-link :to="pageBack" class="button button-secondary">กลับ</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "CMSPage",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = ref({
      title: "",
      show_date: "",
      views: 0,
      image: "",
      detail: "",
      album: [],
      files: [],
      start_date: "",
      location: "",
      contact_person: "",
    });
    const loading = ref(false);
    const error = ref(null);

    const fetchData = async (id) => {
      loading.value = true;
      try {
        let endpoint = `${process.env.VUE_APP_API_URL}/cms-page/${id}`;
        if (route.path.startsWith("/activity")) {
          endpoint = `${process.env.VUE_APP_API_URL}/activity-page/${id}`;
        }
        const response = await axios.get(endpoint);
        if (response.status === 200) {
          data.value = response.data;
          if (!data.value.album) {
            data.value.album = [];
          }
          if (!data.value.files) {
            data.value.files = [];
          }
          // ส่งคำขอเพื่อเพิ่มจำนวนการเข้าชม
          trackPageView();
        } else if (response.status === 404) {
          router.push("/");
        } else {
          handleError("ไม่สามารถโหลดข้อมูลกิจกรรมได้");
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          router.push("/");
        } else {
          handleError(err.response ? err.response.data.message : err.message);
        }
      } finally {
        loading.value = false;
      }
    };

    const trackPageView = async () => {
      const page = window.location.pathname;
      const id = route.params.id;
      let source = "";
      if (route.path.startsWith("/noti")) {
        source = "cms";
      } else if (route.path.startsWith("/highlight")) {
        source = "cms";
      } else if (route.path.startsWith("/privilege")) {
        source = "cms";
      } else if (route.path.startsWith("/activity")) {
        source = "activity";
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/track-page-view`, { id, page, source });
      } catch (err) {
        console.error("Error tracking page view:", err);
      }
    };

    const handleError = (message) => {
      error.value = message;
    };

    onMounted(() => {
      fetchData(route.params.id);
    });

    const formattedDate = (date) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("th-TH", options);
    };

    const formattedViews = (value) => {
      if (!value) return "0";
      return new Intl.NumberFormat().format(value);
    };

    const pageBack = computed(() => {
      if (route.path.startsWith("/noti")) {
        return "/notifications";
      } else if (route.path.startsWith("/highlight")) {
        return "/highlights";
      } else if (route.path.startsWith("/privilege")) {
        return "/privileges";
      } else if (route.path.startsWith("/activity")) {
        return "/activities";
      } else {
        return "/";
      }
    });

    const pageTitle = computed(() => {
      if (route.path.startsWith("/noti")) {
        return "ประกาศ";
      } else if (route.path.startsWith("/highlight")) {
        return "ผลงานเด่น";
      } else if (route.path.startsWith("/privilege")) {
        return "สิทธิประโยชน์";
      } else if (route.path.startsWith("/activity")) {
        return "กิจกรรม";
      } else {
        return "ข้อมูล";
      }
    });

    return {
      pageTitle,
      pageBack,
      data,
      loading,
      error,
      formattedDate,
      formattedViews,
      route,
    };
  },
};
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ebe9f1;
}

.text-header {
  font-size: 2rem;
}

.text-blue {
  font-size: 1.5rem;
  color: #037ee5;
}

.small-text {
  font-size: 14px;
  color: #037ee5;
}

.font-weight-light {
  font-weight: 300;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  margin: 0 auto; /* Center the image */
  display: block; /* Ensure the image is a block element */
}

.icon-circle {
  background-color: #00249a;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.loading-spinner {
  text-align: center;
  font-size: 1.5rem;
  color: #037ee5;
}

.error-message {
  text-align: center;
  color: red;
  font-size: 1.2rem;
  margin-top: 20px;
}

.image-container {
  position: relative;
}

.image {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.file-container {
  display: flex;
  align-items: center;
}

.file-icon {
  font-size: 2rem;
  color: #037ee5;
}

.additional-info {
  background-color: #00249a;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .text-header {
    font-size: 1.5rem;
  }

  .text-blue {
    font-size: 1.25rem;
  }

  .justify-content-md-end {
    justify-content: center !important;
  }
}
</style>
