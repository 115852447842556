<template>
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div class="container my-5 text-start">
    <h1 class="text-primary text-header">{{ textHeader }}</h1>
    <div v-if="error" class="alert alert-danger">
      {{ error }}
    </div>
    <div v-if="isMode === 'disappear' || isMode === 'expire'" class="button-group mt-4 justify-content-center">
      <button @click="useOldData" :class="['button', 'option-button-custom', { selected: selectedOption === 'old', focused: selectedOption === 'old' }]">
        <i class="fa fa-address-card" style="margin-right: 8px"></i> ใช้ข้อมูลปัจจุบัน
      </button>
      <button @click="editNewData" :class="['button', 'option-button-custom', { selected: selectedOption === 'new', focused: selectedOption === 'new' }]">
        <i class="fas fa-edit" style="margin-right: 8px"></i> แก้ไขข้อมูลใหม่
      </button>
    </div>

    <form @submit.prevent="registerUser">
      <div class="row my-4">
        <div class="col-12">
          <div class="profile-picture">
            <div class="image-placeholder">
              <img :src="image_preview || require('@/assets/images/placeholder.jpg')" alt="Profile Picture" class="profile-img" />
            </div>
            <div class="image-actions">
              <div for="profilePicture" class="form-label mt-3">รูปถ่ายผู้สมัครสมาชิก</div>
              <input type="file" class="d-none" id="profilePicture" @change="handleImageUpload" ref="fileInput" :disabled="selectedOption === 'old'" />
              <div class="text-danger mt-2">ต้องเป็นรูปถ่ายหน้าตรง ไม่สวมหมวก ไม่สวมแว่นตา อายุไม่เกิน 6 เดือน</div>
              <div class="text-muted">กรุณาอัพโหลดเฉพาะไฟล์ .jpg, .jpeg หรือ .png เท่านั้น ภาพที่ใช้ในการอัพโหลด ควรมีขนาด 148 x 178 พิกเซล</div>
              <div class="button-group mt-4">
                <button type="button" class="button button-secondary" @click="triggerFileInput" :disabled="selectedOption === 'old'">เลือกไฟล์</button>
                <button type="button" class="button button-secondary" @click="resetImage" :disabled="selectedOption === 'old'">รีเซ็ต</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-2">
        <h4 class="text-primary">ข้อมูลส่วนตัว</h4>
        <div class="col-12 col-md-3">
          <label for="prefix" class="form-label text-second">คำนำหน้าชื่อ *</label>
          <v-select
            ref="prefixSelect"
            v-model="form.m_lookup_prefix_id"
            :options="prefixs"
            :reduce="(prefix) => prefix.id"
            :class="{ 'is-invalid': errors.m_lookup_prefix_id }"
            @update:modelValue="loadGender"
            placeholder="เลือกคำนำหน้าชื่อ"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          >
            <template #option="option">
              <span>{{ option.name_th }} / {{ option.name_en }}</span>
            </template>
            <template #selected-option="option">
              <span>{{ option.name_th }} / {{ option.name_en }}</span>
            </template>
          </v-select>
          <div v-if="errors.m_lookup_prefix_id" class="form-text text-danger">{{ errors.m_lookup_prefix_id }}</div>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-12 col-md-6">
          <label for="firstName" class="form-label text-second">ชื่อ (ไทย) *</label>
          <input type="text" class="form-control custom-input" v-model="form.name" placeholder="กรอกชื่อ (ไทย)" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.name" class="form-text text-danger">{{ errors.name }}</div>
        </div>
        <div class="col-12 col-md-6">
          <label for="lastName" class="form-label text-second">นามสกุล (ไทย) *</label>
          <input type="text" class="form-control custom-input" v-model="form.lastname" placeholder="กรอกนามสกุล (ไทย)" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.lastname" class="form-text text-danger">{{ errors.lastname }}</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <label for="firstNameEng" class="form-label text-second">ชื่อ (อังกฤษ) *</label>
          <input type="text" class="form-control custom-input" v-model="form.name_eng" placeholder="กรอกชื่อ (อังกฤษ)" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.name_eng" class="form-text text-danger">{{ errors.name_eng }}</div>
        </div>
        <div class="col-12 col-md-6">
          <label for="lastNameEng" class="form-label text-second">นามสกุล (อังกฤษ) *</label>
          <input type="text" class="form-control custom-input" v-model="form.lastname_eng" placeholder="กรอกนามสกุล (อังกฤษ)" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.lastname_eng" class="form-text text-danger">{{ errors.lastname_eng }}</div>
        </div>
        <div class="col-12 col-md-6">
          <label for="idCard" class="form-label text-second">เลขประจำตัวประชาชน *</label>
          <input
            :readonly="isMode != 'register'"
            type="text"
            class="form-control custom-input"
            v-model="form.citizen_id"
            maxlength="13"
            placeholder="กรอกเลขประจำตัวประชาชน"
            required
            @input="validateCitizenId"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.citizen_id" class="form-text text-danger">{{ errors.citizen_id }}</div>
        </div>
        <div class="col-12 col-md-3">
          <label for="issueDate" class="form-label text-second">วันออกบัตร</label>
          <VueDatePicker
            v-model="form.issue_date"
            :format="formatDate"
            :class="'custom-input'"
            model-type="yyyy-MM-dd"
            locale="th"
            :format-locale="th"
            placeholder="ระบุวันออกบัตร"
            :enable-time-picker="false"
            :action-row="{ showNow: true }"
            auto-apply
            :disabled="selectedOption === 'old'"
          >
            <template #year="{ value }">{{ value + 543 }}</template>
            <template #year-overlay-value="{ value }"> {{ value + 543 }} </template>
          </VueDatePicker>
        </div>
        <div class="col-12 col-md-3">
          <label for="expiryDate" class="form-label text-second">วันหมดอายุ</label>
          <VueDatePicker
            v-model="form.expiry_date"
            :format="formatDate"
            :class="'custom-input'"
            model-type="yyyy-MM-dd"
            locale="th"
            :format-locale="th"
            placeholder="ระบุวันหมดอายุ"
            :enable-time-picker="false"
            :action-row="{ showNow: true }"
            auto-apply
            :disabled="selectedOption === 'old'"
          >
            <template #year="{ value }">{{ value + 543 }}</template>
            <template #year-overlay-value="{ value }"> {{ value + 543 }} </template>
          </VueDatePicker>

          <small class="form-text text-warning"> หมายเหตุ หากเป็นบัตรประชาชนแบบตลอดชีพไม่ต้องกรอก </small>
        </div>
      </div>
      <div class="row">
        <h4 class="col-md-1 col-lg-1 text-primary d-flex align-items-center"></h4>
        <div class="col-md-11 col-lg-11 d-flex align-items-center small-text checkbox-control">
          <input type="checkbox" id="is_lifetime" class="me-2" v-model="form.is_lifetime" :true-value="1" :false-value="0" :disabled="selectedOption === 'old'" />
          <label class="ms-3" for="is_lifetime">บัตรประชาชนตลอดชีพ</label>
          <span class="icon" :class="{ checked: form.is_lifetime }"></span>
        </div>
        <div v-show="form.is_lifetime == 0" class="col-md-4 col-xl-2">
          <label for="birthDay" class="form-label text-second">เกิดวันที่ *</label>
          <v-select
            ref="birthDaySelect"
            v-model="form.birth_day"
            :options="days"
            placeholder="เลือกวัน"
            :reduce="(day) => day.id"
            label="name"
            :class="{ 'is-invalid': errors.birth_day }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.birth_day" class="form-text text-danger">{{ errors.birth_day }}</div>
        </div>
        <div v-show="form.is_lifetime == 0" class="col-md-4 col-xl-2">
          <label for="birthMonth" class="form-label text-second">เดือน *</label>
          <v-select
            ref="birthMonthSelect"
            v-model="form.birth_month"
            :options="months"
            placeholder="เลือกเดือน"
            :reduce="(month) => month.id"
            label="name"
            :class="{ 'is-invalid': errors.birth_month }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.birth_month" class="form-text text-danger">{{ errors.birth_month }}</div>
        </div>
        <div v-show="form.is_lifetime == 0" class="col-md-4 col-xl-2">
          <label for="birthYear" class="form-label text-second">ปี *</label>
          <v-select
            ref="birthYearSelect"
            v-model="form.birth_year"
            :options="years"
            :reduce="(year) => year.id"
            placeholder="เลือกปี"
            label="name"
            :class="{ 'is-invalid': errors.birth_year }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.birth_year" class="form-text text-danger">{{ errors.birth_year }}</div>
        </div>
        <div class="col-md-4 col-xl-2">
          <label for="gender" class="form-label text-second">เพศ *</label>
          <input type="text" class="form-control custom-input" v-model="form.gender" readonly :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-xl-2">
          <label for="religion" class="form-label text-second">ศาสนา *</label>
          <v-select
            ref="religionSelect"
            v-model="form.m_lookup_religion_id"
            :options="religions"
            :reduce="(religion) => religion.id"
            label="name_th"
            placeholder="เลือกศาสนา"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.m_lookup_religion_id" class="form-text text-danger">{{ errors.m_lookup_religion_id }}</div>
        </div>
        <div class="col-md-4 col-xl-2">
          <label for="bloodType" class="form-label text-second">หมู่โลหิต</label>
          <v-select v-model="form.m_lookup_blood_id" :options="bloodTypes" :reduce="(bloodType) => bloodType.id" label="name_th" placeholder="เลือกหมู่โลหิต" :disabled="selectedOption === 'old'" />
        </div>
      </div>
      <div class="row my-2 mt-5">
        <h4 class="text-primary">ที่อยู่ตามบัตรประจำตัวประชาชน</h4>
        <div class="col-lg-4">
          <label for="building" class="form-label text-second">ชื่ออาคาร *</label>
          <input type="text" class="form-control custom-input" v-model="form.building" placeholder="กรอกชื่ออาคาร" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="addressNumber" class="form-label text-second">เลขที่ *</label>
          <input type="text" class="form-control custom-input" v-model="form.address_no" placeholder="กรอกเลขที่" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.address_no" class="form-text text-danger">{{ errors.address_no }}</div>
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="moo" class="form-label text-second">หมู่ที่ *</label>
          <input type="text" class="form-control custom-input" v-model="form.moo" placeholder="กรอกหมู่ที่" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.moo" class="form-text text-danger">{{ errors.moo }}</div>
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="soi" class="form-label text-second">ซอย *</label>
          <input type="text" class="form-control custom-input" v-model="form.soi" placeholder="กรอกซอย" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="road" class="form-label text-second">ถนน *</label>
          <input type="text" class="form-control custom-input" v-model="form.road" placeholder="กรอกถนน" required :disabled="selectedOption === 'old'" />
          <div v-if="errors.road" class="form-text text-danger">{{ errors.road }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="province" class="form-label text-second">เลือกจังหวัด *</label>
          <v-select
            ref="provinceSelect"
            v-model="form.province_code"
            :options="provinces"
            :reduce="(province) => province.code"
            label="name_th"
            @update:modelValue="loadDistricts"
            placeholder="เลือกจังหวัด"
            :class="{ 'is-invalid': errors.province_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.province_code" class="form-text text-danger">{{ errors.province_code }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="district" class="form-label text-second">เลือกเขต/อำเภอ *</label>
          <v-select
            ref="districtSelect"
            v-model="form.amphur_code"
            :options="districts"
            :reduce="(district) => district.code"
            label="name_th"
            @update:modelValue="loadSubdistricts"
            placeholder="เลือกเขต/อำเภอ"
            :class="{ 'is-invalid': errors.amphur_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.amphur_code" class="form-text text-danger">{{ errors.amphur_code }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="subdistrict" class="form-label text-second">เลือกแขวง/ตำบล *</label>
          <v-select
            ref="subdistrictSelect"
            v-model="form.tumbol_code"
            :options="subdistricts"
            :reduce="(subdistrict) => subdistrict.code"
            label="name_th"
            @update:modelValue="loadZipcode"
            placeholder="เลือกแขวง/ตำบล"
            :class="{ 'is-invalid': errors.tumbol_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.tumbol_code" class="form-text text-danger">{{ errors.tumbol_code }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="zipcode" class="form-label text-second">รหัสไปรษณีย์ *</label>
          <input type="text" class="form-control custom-input" v-model="form.zipcode" placeholder="กรอกรหัสไปรษณีย์" required readonly :disabled="selectedOption === 'old'" />
          <div v-if="errors.zipcode" class="form-text text-danger">{{ errors.zipcode }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="phone" class="form-label text-second">เบอร์โทรศัพท์</label>
          <input type="text" class="form-control custom-input" v-model="form.home_phone" placeholder="กรอกเบอร์โทรศัพท์" :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="fax" class="form-label text-second">โทรสาร</label>
          <input type="text" class="form-control custom-input" v-model="form.fax" placeholder="กรอกโทรสาร" :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="mobile" class="form-label text-second">เบอร์โทรศัพท์มือถือ</label>
          <input type="text" class="form-control custom-input" v-model="form.tel" placeholder="กรอกเบอร์โทรศัพท์มือถือ" :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="email" class="form-label text-second">อีเมล์</label>
          <input type="email" class="form-control custom-input" v-model="form.email" placeholder="กรอกอีเมล์" :disabled="selectedOption === 'old'" />
          <div v-if="errors.email" class="form-text text-danger">{{ errors.email }}</div>
          <small class="form-text text-warning"> หมายเหตุ หากไม่มีอีเมล์ไม่ต้องกรอก </small>
        </div>
      </div>
      <div class="row my-4">
        <h4 class="text-primary">อาชีพ</h4>
        <div v-if="occupations && occupations.length > 0">
          <div class="row">
            <div class="col-12 col-md-3" v-for="occupation in occupations" :key="occupation.id">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="occupation" v-model="form.m_lookup_occupation_id" :value="occupation.id" required :disabled="selectedOption === 'old'" />
                <label class="form-check-label" :for="'occupation' + occupation.id">{{ occupation.name_th }}</label>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="occupation" v-model="form.m_lookup_occupation_id" value="0" required :disabled="selectedOption === 'old'" />
                <label class="form-check-label" for="occupationOther">อื่นๆ</label>
              </div>
              <input
                v-if="form.m_lookup_occupation_id == 0"
                type="text"
                class="form-control custom-input mt-2"
                v-model="form.occupation_text"
                placeholder="กรอกอาชีพอื่นๆ"
                :disabled="selectedOption === 'old'"
              />
            </div>
            <div v-if="errors.m_lookup_occupation_id" class="form-text text-danger">{{ errors.m_lookup_occupation_id }}</div>
          </div>
        </div>
      </div>

      <div class="row my-2 mt-5">
        <h4 class="col-md-3 col-lg-2 text-primary d-flex align-items-center">ที่อยู่ปัจจุบัน</h4>
        <div class="col-md-9 col-lg-10 d-flex align-items-center small-text checkbox-control">
          <input type="checkbox" id="accept" class="me-2" v-model="form.sameAsIdCard" @change="copyAddress" :disabled="selectedOption === 'old'" />
          <label class="ms-3" for="accept">คัดลอกจากที่อยู่ตามบัตรประจำตัวประชาชน</label>
          <span class="icon" :class="{ checked: form.sameAsIdCard }"></span>
        </div>

        <div class="col-lg-4">
          <label for="current_building" class="form-label text-second">ชื่ออาคาร *</label>
          <input type="text" class="form-control custom-input" v-model="form.current_building" placeholder="กรอกชื่ออาคาร" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="current_address_no" class="form-label text-second">เลขที่ *</label>
          <input type="text" class="form-control custom-input" v-model="form.current_address_no" placeholder="กรอกเลขที่" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="current_moo" class="form-label text-second">หมู่ที่ *</label>
          <input type="text" class="form-control custom-input" v-model="form.current_moo" placeholder="กรอกหมู่ที่" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="current_soi" class="form-label text-second">ซอย *</label>
          <input type="text" class="form-control custom-input" v-model="form.current_soi" placeholder="กรอกซอย" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-2">
          <label for="current_road" class="form-label text-second">ถนน *</label>
          <input type="text" class="form-control custom-input" v-model="form.current_road" placeholder="กรอกถนน" required :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="current_province" class="form-label text-second">เลือกจังหวัด *</label>
          <v-select
            ref="currentProvinceSelect"
            v-model="form.current_province_code"
            :options="provinces"
            :reduce="(province) => province.code"
            label="name_th"
            @update:modelValue="loadCurrentDistricts"
            placeholder="เลือกจังหวัด"
            :class="{ 'is-invalid': errors.current_province_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.current_province_code" class="form-text text-danger">{{ errors.current_province_code }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="current_district" class="form-label text-second">เลือกเขต/อำเภอ *</label>
          <v-select
            ref="currentDistrictSelect"
            v-model="form.current_amphur_code"
            :options="currentDistricts"
            :reduce="(district) => district.code"
            label="name_th"
            @update:modelValue="loadCurrentSubdistricts"
            placeholder="เลือกเขต/อำเภอ"
            :class="{ 'is-invalid': errors.current_amphur_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.current_amphur_code" class="form-text text-danger">{{ errors.current_amphur_code }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="current_subdistrict" class="form-label text-second">เลือกแขวง/ตำบล *</label>
          <v-select
            ref="currentSubdistrictSelect"
            v-model="form.current_tumbol_code"
            :options="currentSubdistricts"
            :reduce="(subdistrict) => subdistrict.code"
            label="name_th"
            @update:modelValue="loadCurrentZipcode"
            placeholder="เลือกแขวง/ตำบล"
            :class="{ 'is-invalid': errors.current_tumbol_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.current_tumbol_code" class="form-text text-danger">{{ errors.current_tumbol_code }}</div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="current_zipcode" class="form-label text-second">รหัสไปรษณีย์ *</label>
          <input type="text" class="form-control custom-input" v-model="form.current_zipcode" placeholder="กรอกรหัสไปรษณีย์" required readonly :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="current_home_phone" class="form-label text-second">เบอร์โทรศัพท์</label>
          <input type="text" class="form-control custom-input" v-model="form.current_home_phone" placeholder="กรอกเบอร์โทรศัพท์" :disabled="selectedOption === 'old'" />
        </div>
        <div class="col-md-4 col-lg-3">
          <label for="current_fax" class="form-label text-second">โทรสาร</label>
          <input type="text" class="form-control custom-input" v-model="form.current_fax" placeholder="กรอกโทรสาร" :disabled="selectedOption === 'old'" />
        </div>
      </div>

      <div class="row my-2 mt-5">
        <div class="col-lg-6 mt-1">
          <h6 class="text-primary">ตำแหน่งปัจจุบัน / สถานภาพทางสังคม *</h6>
          <v-select
            ref="positionSelect"
            v-model="form.m_lookup_position_id"
            :options="positions"
            :reduce="(position) => position.id"
            label="name_th"
            placeholder="เลือกตำแหน่งปัจจุบัน / สถานภาพทางสังคม"
            :class="{ 'is-invalid': errors.m_lookup_position_id }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.m_lookup_position_id" class="form-text text-danger">{{ errors.m_lookup_position_id }}</div>
        </div>
        <div class="col-md-6 col-lg-3 mt-1">
          <h6 class="text-primary">การศึกษา *</h6>
          <v-select
            ref="educationSelect"
            v-model="form.m_lookup_education_id"
            :options="educations"
            :reduce="(education) => education.id"
            label="name_th"
            placeholder="เลือกการศึกษา"
            :class="{ 'is-invalid': errors.m_lookup_education_id }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.m_lookup_education_id" class="form-text text-danger">{{ errors.m_lookup_education_id }}</div>
        </div>
        <div class="col-md-6 col-lg-3 mt-1">
          <h6 class="text-primary">มีความประสงค์เป็น อสทล. จังหวัด *</h6>
          <v-select
            ref="desiredProvinceSelect"
            v-model="form.desired_province_code"
            :options="desiredProvinces"
            :reduce="(province) => province.code"
            label="name_th"
            placeholder="เลือกจังหวัด"
            :class="{ 'is-invalid': errors.desired_province_code }"
            @blur="validateForm"
            :disabled="selectedOption === 'old'"
          />
          <div v-if="errors.desired_province_code" class="form-text text-danger">{{ errors.desired_province_code }}</div>
        </div>
      </div>

      <div class="row my-2 mt-5">
        <div class="col-12">
          <div class="profile-picture">
            <div class="image-idcard">
              <img :src="id_card_image_preview || require('@/assets/images/card-default.jpg')" alt="ID Card Picture" class="profile-img" />
            </div>
            <div class="image-actions">
              <div for="idCardPicture" class="form-label mt-3">สำเนาบัตรประจำตัวประชาชน</div>
              <input type="file" class="d-none" id="idCardPicture" @change="handleIdCardImageUpload" ref="fileInputIdCard" :disabled="selectedOption === 'old'" />
              <div class="text-muted">กรุณาอัพโหลดเฉพาะไฟล์ .jpg หรือ .jpeg เท่านั้น ภาพที่ให้อัพโหลด ควรมีขนาด 488 x 306 พิกเซล</div>
              <div class="button-group mt-4">
                <button type="button" class="button button-secondary" @click="triggerFileInputIdCard" :disabled="selectedOption === 'old'">เลือกไฟล์</button>
                <button type="button" class="button button-secondary" @click="resetImageIdCard" :disabled="selectedOption === 'old'">รีเซ็ต</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-2 mt-5 justify-content-center">
        <h6 class="text-primary text-center">สถานที่จัดส่งบัตรประจำตัวอาสาสมัคร</h6>
        <div class="col-12 col-md-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="deliveryLocation" v-model="form.delivery_location_type" value="1" required />
            <label class="form-check-label" for="deliveryLocation1">ที่อยู่ตามบัตรประจำตัวประชาชน</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="deliveryLocation" v-model="form.delivery_location_type" value="2" required />
            <label class="form-check-label" for="deliveryLocation2">ที่อยู่ปัจจุบัน</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="deliveryLocation" v-model="form.delivery_location_type" value="0" required />
            <label class="form-check-label" for="deliveryLocation3">อื่นๆ</label>
          </div>
          <input v-if="form.delivery_location_type == 0" type="text" class="form-control custom-input mt-2" v-model="form.delivery_other" placeholder="กรอกสถานที่จัดส่งอื่นๆ" />
          <div v-if="errors.delivery_location_type" class="form-text text-danger">{{ errors.delivery_location_type }}</div>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-12 d-flex justify-content-center mt-5">
          <button type="submit" class="button button-secondary me-5">{{ buttonOk }}</button>
          <template v-if="isMode == 'register'">
            <router-link to="/" class="button button-secondary"> ยกเลิก </router-link>
          </template>
          <template v-else-if="isMode != 'register'">
            <router-link to="/profile" class="button button-secondary"> ยกเลิก </router-link>
          </template>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { ref, onMounted, watchEffect, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import vSelect from "vue-select";
import { months } from "@/data/stores";
import { th } from "date-fns/locale";
import "vue-select/dist/vue-select.css";
import { showAlert, resizeImage } from "@/utils/utils";

export default {
  name: "RegisterPage",
  components: { vSelect },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const isMode = ref(null);
    const error = ref(null);
    const errors = ref({});
    const router = useRouter();
    const textHeader = ref("");
    const buttonOk = ref("");
    const selectedOption = ref("new");
    const showForm = ref(true);
    const image_preview = ref(null);
    const id_card_image_preview = ref(null);
    const form = ref({
      m_lookup_prefix_id: null,
      name: "",
      lastname: "",
      name_eng: "",
      lastname_eng: "",
      citizen_id: "",
      issue_date: "",
      expiry_date: "",
      is_lifetime: 0,
      birth_day: null,
      birth_month: null,
      birth_year: null,
      gender: "",
      m_lookup_blood_id: "",
      m_lookup_religion_id: "",
      building: "",
      address_no: "",
      moo: "",
      soi: "",
      road: "",
      province_code: "",
      amphur_code: "",
      tumbol_code: "",
      zipcode: "",
      home_phone: "",
      fax: "",
      tel: "",
      email: "",
      m_lookup_occupation_id: null,
      occupation_text: "",
      current_building: "",
      current_address_no: "",
      current_moo: "",
      current_soi: "",
      current_road: "",
      current_province_code: "",
      current_amphur_code: "",
      current_tumbol_code: "",
      current_zipcode: "",
      current_home_phone: "",
      current_fax: "",
      m_lookup_position_id: "",
      m_lookup_education_id: "",
      desired_province_code: "",
      delivery_location_type: "",
      delivery_other: "",
      sameAsIdCard: false,
      image: "",
      id_card_image: "",
    });

    const fileInput = ref(null);
    const fileInputIdCard = ref(null);
    const prefixSelect = ref(null);
    const citizenIdSelect = ref(null);
    const birthDaySelect = ref(null);
    const birthMonthSelect = ref(null);
    const birthYearSelect = ref(null);
    const religionSelect = ref(null);
    const provinceSelect = ref(null);
    const districtSelect = ref(null);
    const subdistrictSelect = ref(null);
    const currentProvinceSelect = ref(null);
    const currentDistrictSelect = ref(null);
    const currentSubdistrictSelect = ref(null);
    const positionSelect = ref(null);
    const educationSelect = ref(null);
    const desiredProvinceSelect = ref(null);

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const triggerFileInputIdCard = () => {
      fileInputIdCard.value.click();
    };

    const prefixs = ref([]);
    const days = ref(generateDays());
    const years = ref(generateYears());
    const religions = ref([]);
    const bloodTypes = ref([]);
    const positions = ref([]);
    const educations = ref([]);
    const provinces = ref([]);
    const districts = ref([]);
    const subdistricts = ref([]);
    const currentDistricts = ref([]);
    const currentSubdistricts = ref([]);
    const desiredProvinces = ref([]);
    const occupations = ref([]);

    const loadInitialData = async () => {
      loading.value = true;
      try {
        const [prefixResponse, religionResponse, bloodTypeResponse, positionResponse, educationResponse, provinceResponse, occupationResponse] = await Promise.all([
          axios.get(`${process.env.VUE_APP_API_URL}/prefixs`),
          axios.get(`${process.env.VUE_APP_API_URL}/religions`),
          axios.get(`${process.env.VUE_APP_API_URL}/blood-types`),
          axios.get(`${process.env.VUE_APP_API_URL}/positions`),
          axios.get(`${process.env.VUE_APP_API_URL}/educations`),
          axios.get(`${process.env.VUE_APP_API_URL}/provinces`),
          axios.get(`${process.env.VUE_APP_API_URL}/occupations`),
        ]);

        prefixs.value = prefixResponse.data;
        religions.value = religionResponse.data;
        bloodTypes.value = bloodTypeResponse.data;
        positions.value = positionResponse.data;
        educations.value = educationResponse.data;
        provinces.value = provinceResponse.data;
        desiredProvinces.value = provinceResponse.data.filter((province) => province.desired_province_type === 1);
        occupations.value = occupationResponse.data;

        const savedForm = JSON.parse(localStorage.getItem("userInfoWeb"));
        if (isMode.value != "register") {
          if (savedForm) {
            loadSavedForm(savedForm);
          }
        }
      } catch (err) {
        error.value = "Error loading lookup data: " + err.message;
      }
      loading.value = false;
    };

    const loadSavedForm = async (data) => {
      // Load fields from JSON
      Object.assign(form.value, data);

      if (data.image) {
        image_preview.value = data.image;
      }
      if (data.id_card_image) {
        id_card_image_preview.value = data.id_card_image;
      }

      // Load districts and subdistricts for the main address
      if (form.value.province_code) {
        await loadDistricts(form.value.province_code, true);
      }
      if (form.value.amphur_code) {
        await loadSubdistricts(form.value.amphur_code, true);
      }
      if (form.value.tumbol_code) {
        await loadZipcode(form.value.tumbol_code);
      }

      // Load districts and subdistricts for the current address
      if (form.value.current_province_code) {
        await loadCurrentDistricts(form.value.current_province_code, true);
      }
      if (form.value.current_amphur_code) {
        await loadCurrentSubdistricts(form.value.current_amphur_code, true);
      }
      if (form.value.current_tumbol_code) {
        await loadCurrentZipcode(form.value.current_tumbol_code);
      }
    };

    const loadGender = (prefixId) => {
      const selectedPrefix = prefixs.value.find((prefix) => prefix.id === prefixId);
      if (selectedPrefix) {
        form.value.gender = selectedPrefix.gender;
      } else {
        form.value.gender = "";
      }
    };

    const loadDistricts = async (code, initialLoad = false) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/amphurs/getMAmphurByProvinceCode/${code}`);
        districts.value = response.data;
        if (!initialLoad) {
          subdistricts.value = [];
          form.value.amphur_code = "";
          form.value.tumbol_code = "";
          form.value.zipcode = "";
        }
      } catch (err) {
        console.error("Error loading districts:", err);
      }
    };

    const loadSubdistricts = async (code, initialLoad = false) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/tumbols/getMTumbolByAmphurCode/${code}`);
        subdistricts.value = response.data;
        if (!initialLoad) {
          form.value.tumbol_code = "";
          form.value.zipcode = "";
        }
      } catch (err) {
        console.error("Error loading subdistricts:", err);
      }
    };

    const loadZipcode = async (code) => {
      try {
        const selectedSubdistrict = subdistricts.value.find((subdistrict) => subdistrict.code === code);
        if (selectedSubdistrict) {
          form.value.zipcode = selectedSubdistrict.post_code;
        } else {
          form.value.zipcode = "";
        }
      } catch (err) {
        console.error("Error loading zipcodes:", err);
      }
    };

    const loadCurrentDistricts = async (code, initialLoad = false) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/amphurs/getMAmphurByProvinceCode/${code}`);
        currentDistricts.value = response.data;
        if (!initialLoad) {
          currentSubdistricts.value = [];
          form.value.current_amphur_code = "";
          form.value.current_tumbol_code = "";
          form.value.current_zipcode = "";
        }
      } catch (err) {
        console.error("Error loading current districts:", err);
      }
    };

    const loadCurrentSubdistricts = async (code, initialLoad = false) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/tumbols/getMTumbolByAmphurCode/${code}`);
        currentSubdistricts.value = response.data;
        if (!initialLoad) {
          form.value.current_tumbol_code = "";
          form.value.current_zipcode = "";
        }
      } catch (err) {
        console.error("Error loading current subdistricts:", err);
      }
    };

    const loadCurrentZipcode = async (code) => {
      try {
        const selectedSubdistrict = currentSubdistricts.value.find((subdistrict) => subdistrict.code === code);
        if (selectedSubdistrict) {
          form.value.current_zipcode = selectedSubdistrict.post_code;
        } else {
          form.value.current_zipcode = "";
        }
      } catch (err) {
        console.error("Error loading current zipcodes:", err);
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        resizeImage(file, 148, 178, (resizedFile, resizedImageBase64) => {
          image_preview.value = resizedImageBase64;
          form.value.image = resizedFile;
        });
      } else {
        console.error("No file selected or the selected file is not valid.");
      }
    };

    const resetImage = () => {
      form.value.image = "";
      fileInput.value.value = null;
      image_preview.value = null;
    };

    const resetImageIdCard = () => {
      form.value.id_card_image = "";
      fileInputIdCard.value.value = null;
      id_card_image_preview.value = null;
    };

    const handleIdCardImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          id_card_image_preview.value = e.target.result;
        };
        reader.readAsDataURL(file);
        form.value.id_card_image = file;
      } else {
        console.error("No file selected or the selected file is not valid.");
      }
    };

    const copyAddress = async () => {
      if (form.value.sameAsIdCard) {
        form.value.current_building = form.value.building;
        form.value.current_address_no = form.value.address_no;
        form.value.current_moo = form.value.moo;
        form.value.current_soi = form.value.soi;
        form.value.current_road = form.value.road;
        form.value.current_home_phone = form.value.home_phone;
        form.value.current_fax = form.value.fax;

        form.value.current_province_code = form.value.province_code;
        form.value.current_amphur_code = "";
        form.value.current_tumbol_code = "";
        form.value.current_zipcode = "";

        // Load districts and subdistricts for the current address
        if (form.value.province_code) {
          await loadCurrentDistricts(form.value.province_code, true);
          form.value.current_amphur_code = form.value.amphur_code;
        }
        if (form.value.amphur_code) {
          await loadCurrentSubdistricts(form.value.amphur_code, true);
          form.value.current_tumbol_code = form.value.tumbol_code;
        }
        if (form.value.tumbol_code) {
          await loadCurrentZipcode(form.value.tumbol_code);
        }
      }
    };

    const validateForm = async () => {
      errors.value = {};

      const focusNextTick = async (ref) => {
        await nextTick();
        if (ref && ref.value && ref.value.$el) {
          const input = ref.value.$el.querySelector("input");
          if (input) {
            input.focus();
          } else if (ref.value.focus) {
            ref.value.focus();
          }
        }
      };

      const today = new Date();
      const birthDate = new Date(form.value.birth_year - 543, form.value.birth_month - 1, form.value.birth_day);
      const diffTime = today - birthDate;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      const age = diffDays / 365.25;

      if (!form.value.m_lookup_prefix_id) {
        errors.value.m_lookup_prefix_id = "กรุณาเลือกคำนำหน้าชื่อ";
        await focusNextTick(prefixSelect);
      } else if (!form.value.birth_day && form.value.is_lifetime == 0) {
        errors.value.birth_day = "กรุณาเลือกวัน";
        await focusNextTick(birthDaySelect);
      } else if (!form.value.birth_month && form.value.is_lifetime == 0) {
        errors.value.birth_month = "กรุณาเลือกเดือน";
        await focusNextTick(birthMonthSelect);
      } else if (!form.value.birth_year && form.value.is_lifetime == 0) {
        errors.value.birth_year = "กรุณาเลือกปี";
        await focusNextTick(birthYearSelect);
      } else if (age < 18) {
        errors.value.birth_year = "ผู้สมัครต้องมีอายุไม่ต่ำกว่า 18 ปี";
        await focusNextTick(birthYearSelect);
      } else if (!form.value.m_lookup_religion_id) {
        errors.value.m_lookup_religion_id = "กรุณาเลือกศาสนา";
        await focusNextTick(religionSelect);
      } else if (!form.value.province_code) {
        errors.value.province_code = "กรุณาเลือกจังหวัด";
        await focusNextTick(provinceSelect);
      } else if (!form.value.amphur_code) {
        errors.value.amphur_code = "กรุณาเลือกเขต/อำเภอ";
        await focusNextTick(districtSelect);
      } else if (!form.value.tumbol_code) {
        errors.value.tumbol_code = "กรุณาเลือกแขวง/ตำบล";
        await focusNextTick(subdistrictSelect);
      } else if (!form.value.current_province_code) {
        errors.value.current_province_code = "กรุณาเลือกจังหวัด";
        await focusNextTick(currentProvinceSelect);
      } else if (!form.value.current_amphur_code) {
        errors.value.current_amphur_code = "กรุณาเลือกเขต/อำเภอ";
        await focusNextTick(currentDistrictSelect);
      } else if (!form.value.current_tumbol_code) {
        errors.value.current_tumbol_code = "กรุณาเลือกแขวง/ตำบล";
        await focusNextTick(currentSubdistrictSelect);
      } else if (!form.value.m_lookup_position_id) {
        errors.value.m_lookup_position_id = "กรุณาเลือกตำแหน่งปัจจุบัน / สถานภาพทางสังคม";
        await focusNextTick(positionSelect);
      } else if (!form.value.m_lookup_education_id) {
        errors.value.m_lookup_education_id = "กรุณาเลือกการศึกษา";
        await focusNextTick(educationSelect);
      } else if (!form.value.desired_province_code) {
        errors.value.desired_province_code = "กรุณาเลือกความประสงค์เป็น อสทล. จังหวัด";
        await focusNextTick(desiredProvinceSelect);
      } else if (!form.value.delivery_location_type) {
        errors.value.delivery_location_type = "กรุณาเลือกสถานที่จัดส่งบัตรประจำตัวอาสาสมัคร";
      } else if (!form.value.m_lookup_occupation_id && form.value.m_lookup_occupation_id != 0) {
        errors.value.m_lookup_occupation_id = "กรุณาเลือกอาชีพ";
      } else if (form.value.delivery_location_type == 0 && form.value.delivery_other == "") {
        errors.value.delivery_location_type = "กรุณากรอกสถานที่จัดส่ง";
      }

      if (form.value.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.value.email)) {
        errors.value.email = "กรุณาระบุรูปแบบอีเมลให้ถูกต้อง";
      }

      if (!form.value.citizen_id || form.value.citizen_id.length !== 13) {
        errors.value.citizen_id = "กรุณากรอกเลขประจำตัวประชาชนให้ครบ 13 หลัก";
      }
    };
    const registerUser = async () => {
      validateForm();
      if (Object.keys(errors.value).length > 0) {
        return;
      }

      // Add confirmation alert
      const isConfirmed = confirm(`คุณแน่ใจว่าต้องการ${buttonOk.value}ใช่หรือไม่?`);
      if (!isConfirmed) {
        return;
      }

      // Extract values for the birth date fields
      const birthDayValue = form.value.birth_day ? form.value.birth_day : "";
      const birthMonthValue = form.value.birth_month ? form.value.birth_month : "";
      const birthYearValue = form.value.birth_year ? form.value.birth_year : "";
      let password = "";
      // Generate password from birth date
      if (form.value.is_lifetime == 1) {
        password = `99999999`;
      } else {
        password = `${String(birthDayValue).padStart(2, "0")}${String(birthMonthValue).padStart(2, "0")}${birthYearValue}`;
      }

      loading.value = true;
      try {
        const formData = new FormData();
        const appendFormData = (key, value) => formData.append(key, value == null ? "" : value);

        appendFormData("username", form.value.citizen_id);
        appendFormData("password", password);
        appendFormData("m_lookup_prefix_id", form.value.m_lookup_prefix_id);
        appendFormData("name", form.value.name);
        appendFormData("lastname", form.value.lastname);
        appendFormData("name_eng", form.value.name_eng);
        appendFormData("lastname_eng", form.value.lastname_eng);
        appendFormData("citizen_id", form.value.citizen_id);
        appendFormData("issue_date", form.value.issue_date);
        appendFormData("expiry_date", form.value.expiry_date);
        appendFormData("is_lifetime", form.value.is_lifetime);
        appendFormData("birth_day", birthDayValue);
        appendFormData("birth_month", birthMonthValue);
        appendFormData("birth_year", birthYearValue);
        appendFormData("gender", form.value.gender);
        appendFormData("m_lookup_blood_id", form.value.m_lookup_blood_id);
        appendFormData("m_lookup_religion_id", form.value.m_lookup_religion_id);
        appendFormData("building", form.value.building);
        appendFormData("address_no", form.value.address_no);
        appendFormData("moo", form.value.moo);
        appendFormData("soi", form.value.soi);
        appendFormData("road", form.value.road);
        appendFormData("province_code", form.value.province_code);
        appendFormData("amphur_code", form.value.amphur_code);
        appendFormData("tumbol_code", form.value.tumbol_code);
        appendFormData("zipcode", form.value.zipcode);
        appendFormData("home_phone", form.value.home_phone);
        appendFormData("fax", form.value.fax);
        appendFormData("tel", form.value.tel);
        appendFormData("email", form.value.email);
        appendFormData("m_lookup_occupation_id", form.value.m_lookup_occupation_id);
        appendFormData("occupation_text", form.value.occupation_text);
        appendFormData("current_building", form.value.current_building);
        appendFormData("current_address_no", form.value.current_address_no);
        appendFormData("current_moo", form.value.current_moo);
        appendFormData("current_soi", form.value.current_soi);
        appendFormData("current_road", form.value.current_road);
        appendFormData("current_province_code", form.value.current_province_code);
        appendFormData("current_amphur_code", form.value.current_amphur_code);
        appendFormData("current_tumbol_code", form.value.current_tumbol_code);
        appendFormData("current_zipcode", form.value.current_zipcode);
        appendFormData("current_home_phone", form.value.current_home_phone);
        appendFormData("current_fax", form.value.current_fax);
        appendFormData("m_lookup_position_id", form.value.m_lookup_position_id);
        appendFormData("m_lookup_education_id", form.value.m_lookup_education_id);
        appendFormData("desired_province_code", form.value.desired_province_code);
        appendFormData("delivery_location_type", form.value.delivery_location_type);
        appendFormData("delivery_other", form.value.delivery_other);
        appendFormData("version", form.value.version ?? 0);
        appendFormData("user_by", form.value.name + " " + form.value.lastname);
        appendFormData("userCurrent", selectedOption.value == "old" ? 1 : 0);

        // เพิ่มไฟล์รูปภาพเข้าไปใน FormData
        if (form.value.image instanceof File) {
          appendFormData("image", form.value.image);
        } else if (form.value.image && form.value.image.startsWith("data:image")) {
          // ถ้าเป็น base64 ให้แปลงเป็น blob แล้วเพิ่มเข้าไป
          const blob = await (await fetch(form.value.image)).blob();
          appendFormData("image", blob, "image.jpg");
        }

        if (form.value.id_card_image instanceof File) {
          appendFormData("id_card_image", form.value.id_card_image);
        } else if (form.value.id_card_image && form.value.id_card_image.startsWith("data:image")) {
          // ถ้าเป็น base64 ให้แปลงเป็น blob แล้วเพิ่มเข้าไป
          const blob = await (await fetch(form.value.id_card_image)).blob();
          appendFormData("id_card_image", blob, "id_card_image.jpg");
        }

        let apiUrl = "";

        if (isMode.value == "register") {
          apiUrl = `${process.env.VUE_APP_API_URL}/user-register`;
        } else if (isMode.value == "edit") {
          apiUrl = `${process.env.VUE_APP_API_URL}/user-general-update`;
        } else if (isMode.value == "expire") {
          apiUrl = `${process.env.VUE_APP_API_URL}/user-card-expire`;
        } else if (isMode.value == "disappear") {
          apiUrl = `${process.env.VUE_APP_API_URL}/user-card-disappear`;
        }

        const token = localStorage.getItem("authTokenWeb");

        await axios({ method: "post", url: apiUrl, data: formData, headers: { Authorization: `Bearer ${token}` } });

        showAlert({ title: isMode.value == "register" ? `ทำการสมัครเรียบร้อยแล้ว` : `ดำเนินการเรียบร้อยแล้ว`, icon: "success" });
        localStorage.removeItem("authTokenWeb");
        localStorage.removeItem("userInfoWeb");
        localStorage.removeItem("needsSurvey");
        router.push("/");
      } catch (err) {
        error.value = "Error registering user: " + err.response.data.message;
        showAlert({ title: err.response.data.message, icon: "error" });
      }
      loading.value = false;
    };

    const validateCitizenId = () => {
      form.value.citizen_id = form.value.citizen_id.replace(/\D/g, "").slice(0, 13);
    };

    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear() + 543;

      return `${day}/${month}/${year}`;
    };

    const useOldData = () => {
      selectedOption.value = "old";
      showForm.value = false;
    };

    const editNewData = () => {
      selectedOption.value = "new";
      showForm.value = true;
    };

    watchEffect(async () => {
      await nextTick();
      window.scrollTo(0, 0);
      Object.assign(form.value, {
        m_lookup_prefix_id: null,
        name: "",
        lastname: "",
        name_eng: "",
        lastname_eng: "",
        citizen_id: "",
        issue_date: "",
        expiry_date: "",
        is_lifetime: 0,
        birth_day: null,
        birth_month: null,
        birth_year: null,
        gender: "",
        m_lookup_blood_id: "",
        m_lookup_religion_id: "",
        building: "",
        address_no: "",
        moo: "",
        soi: "",
        road: "",
        province_code: "",
        amphur_code: "",
        tumbol_code: "",
        zipcode: "",
        home_phone: "",
        fax: "",
        tel: "",
        email: "",
        m_lookup_occupation_id: "",
        occupation_text: "",
        current_building: "",
        current_address_no: "",
        current_moo: "",
        current_soi: "",
        current_road: "",
        current_province_code: "",
        current_amphur_code: "",
        current_tumbol_code: "",
        current_zipcode: "",
        current_home_phone: "",
        current_fax: "",
        m_lookup_position_id: "",
        m_lookup_education_id: "",
        desired_province_code: "",
        delivery_location_type: "",
        delivery_other: "",
        sameAsIdCard: false,
        image: "",
        id_card_image: "",
      });
    });

    onMounted(() => {
      document.querySelectorAll(".v-select .vs__dropdown-toggle").forEach((el) => {
        el.style.border = "none";
        el.style.boxShadow = "none";
        el.style.padding = "0";
      });
      document.querySelectorAll(".v-select .vs__clear").forEach((el) => {
        el.style.marginTop = "-3px";
      });
      document.querySelectorAll(".v-select .vs__search").forEach((el) => {
        el.style.fontSize = "13px";
      });

      document.querySelectorAll(".dp__input_icon_pad").forEach((el) => {
        el.style.fontFamily = "Kanit";
        el.style.fontSize = "0.84rem";
        el.style.border = "0";
      });

      document.querySelectorAll(".dp__theme_light").forEach((el) => {
        el.style.padding = "4px 8px";
      });

      if (route.name === "RegisterPage") {
        isMode.value = "register";
        textHeader.value = "สมัครสมาชิกอาสาสมัครพิทักษ์ทะเล";
        buttonOk.value = "ยืนยันการสมัคร";
        selectedOption.value = "new";
        showForm.value = true;
      } else if (route.name === "EditProfilePage") {
        isMode.value = "edit";
        textHeader.value = "แก้ไขข้อมูลอาสาสมัครพิทักษ์ทะเล";
        buttonOk.value = "แก้ไขข้อมูล";
        selectedOption.value = "new";
        showForm.value = true;
      } else if (route.name === "CardExpirePage") {
        isMode.value = "expire";
        textHeader.value = "แจ้งต่ออายุบัตร";
        buttonOk.value = "แจ้งต่ออายุบัตร";
        selectedOption.value = "old";
        showForm.value = false;
      } else if (route.name === "CardDisappearPage") {
        isMode.value = "disappear";
        textHeader.value = "แจ้งบัตรหาย";
        buttonOk.value = "แจ้งบัตรหาย";
        selectedOption.value = "old";
        showForm.value = false;
      }

      const savedForm = JSON.parse(localStorage.getItem("userInfoWeb"));
      if (savedForm && savedForm.is_approve == 1 && isMode.value == "edit") {
        router.push("/profile");
        return false;
      } else if (savedForm && savedForm.is_approve == 0 && (isMode.value == "expire" || isMode.value == "disappear")) {
        router.push("/profile");
        return false;
      } else if (savedForm) {
        loadSavedForm(savedForm);
      }

      loadInitialData();
    });

    return {
      textHeader,
      buttonOk,
      form,
      isMode,
      error,
      errors,
      th,
      prefixs,
      days,
      months,
      years,
      religions,
      bloodTypes,
      positions,
      educations,
      provinces,
      districts,
      subdistricts,
      currentDistricts,
      currentSubdistricts,
      desiredProvinces,
      occupations,
      image_preview,
      id_card_image_preview,
      handleImageUpload,
      handleIdCardImageUpload,
      copyAddress,
      registerUser,
      loading,
      useOldData,
      editNewData,
      validateCitizenId,
      loadGender,
      loadDistricts,
      loadSubdistricts,
      loadZipcode,
      loadCurrentDistricts,
      loadCurrentSubdistricts,
      loadCurrentZipcode,
      formatDate,
      triggerFileInput,
      fileInput,
      triggerFileInputIdCard,
      fileInputIdCard,
      resetImage,
      resetImageIdCard,
      prefixSelect,
      citizenIdSelect,
      birthDaySelect,
      birthMonthSelect,
      birthYearSelect,
      religionSelect,
      provinceSelect,
      districtSelect,
      subdistrictSelect,
      currentProvinceSelect,
      currentDistrictSelect,
      currentSubdistrictSelect,
      positionSelect,
      educationSelect,
      desiredProvinceSelect,
      showForm,
      selectedOption,
    };
  },
};

function generateDays() {
  return Array.from({ length: 31 }, (v, k) => ({ id: k + 1, name: (k + 1).toString() }));
}

function generateYears() {
  const currentYear = new Date().getFullYear() + 543; // Thai Buddhist calendar year
  return Array.from({ length: 101 }, (v, k) => {
    const year = currentYear - k;
    return { id: year, name: year.toString() };
  });
}
</script>

<style scoped>
.text-header {
  font-size: 2rem;
  font-weight: bold;
}

.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  padding: 20px;
  flex-direction: column;
}

.profile-picture .image-placeholder {
  border: 2px solid #037de4;
  border-radius: 5px;
  width: 148px;
  height: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  overflow: hidden;
}

.profile-picture .image-idcard {
  border: 2px solid #037de4;
  border-radius: 5px;
  width: 388px;
  height: 206px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  overflow: hidden;
}

.profile-picture .text-danger,
.profile-picture .text-muted {
  font-size: 12px;
}

.profile-picture .button {
  height: 40px;
  width: 150px;
  font-size: 14px;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-actions {
  margin-left: 24px;
}

.button-group {
  display: flex;
  gap: 8px;
}

.profile-picture .form-label {
  color: #037de4;
  font-size: 1.1rem;
  font-weight: bold;
}

.custom-input {
  font-size: 0.9rem;
  border-radius: 50px;
  padding: 10px 20px;
  border: 1px solid #ccc;
}

.custom-input::placeholder {
  color: #888;
  font-size: 0.8em;
}

.custom-input:focus {
  border-color: #037de4;
  box-shadow: none;
}

.v-select {
  font-size: 0.9rem;
  border-radius: 50px;
  padding: 8px 20px;
  height: 44px;
  border: 1px solid #ccc;
}

.v-select .vs__search {
  padding: 0 10px;
}

.v-select .vs__selected {
  padding: 0 10px;
}

.v-select .vs__actions {
  margin-right: 10px;
}

.v-select.is-invalid {
  border-color: red;
}

.v-select.is-invalid .vs__dropdown-toggle {
  border-color: red;
}

.error-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: red;
  display: none;
}

.form-label.text-second {
  color: #858585 !important;
  font-size: 12px;
  margin-left: 16px;
}

.form-text {
  font-size: 11px;
  margin-left: 13px;
}

.form-check .form-check-label {
  font-size: 0.8em;
}

.sameAsIdCard {
  font-size: 14px;
  color: #2c3e50;
  font-weight: 400;
}

.custom-datepicker {
  font-size: 1rem;
  border-radius: 50px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  width: 100%;
}

.custom-datepicker input {
  font-size: 0.8em;
  border-radius: 50px;
  padding: 10px 20px;
  border: none;
  width: 100%;
}

.custom-datepicker input::placeholder {
  color: #888;
  font-size: 0.8em;
}

.custom-datepicker input:focus {
  border-color: #037de4;
  box-shadow: none;
}

.checkbox-control {
  left: 0;
}

.checkbox-control label {
  top: -1px;
}

.option-button-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #037ee5;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: white;
  color: #037ee5;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option-button-custom .circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #037ee5;
  margin-right: 10px;
}

.option-button-custom.selected,
.option-button-custom.focused {
  background-color: #037ee5;
  color: white;
}

.option-button-custom.selected .circle,
.option-button-custom.focused .circle {
  background-color: white;
}

@media (max-width: 767px) {
  .profile-picture {
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .profile-picture {
    flex-direction: row;
  }

  .checkbox-control {
    left: -20px;
  }

  .checkbox-control label {
    top: -5px;
  }
}

@media (min-width: 1198px) {
  .checkbox-control {
    left: -50px;
  }
}

@media (min-width: 1400px) {
  .checkbox-control {
    left: -80px;
  }
}
</style>
